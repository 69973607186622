import { Component, OnInit } from '@angular/core';
import { UsuarioService} from '../../services/usuario.service';
import { EncuestaAreaService } from '../../services/encuestaarea.service';
import { EncuestaArea } from '../../models/encuestaarea';
import { Pregunta } from '../../models/pregunta';
import { Usuario } from '../../models/usuario';
import { EvaluacionService } from '../../services/evaluacion.service';
import { EvaluacionDetalleService } from '../../services/evaluaciondetalle.service';
import { EvaluacionDetalle } from '../../models/evaluaciondetalle';
import { GLOBAL } from '../../services/global';
import { Respuesta } from 'src/app/models/respuesta';
import { Reconocimiento } from 'src/app/models/reconocimiento';
import { Router, ActivatedRoute } from '@angular/router';
import { FolioAreaService } from '../../services/folioarea.service';
import { HelperService } from '../../services/helpers.service';
import { EquipoFolioService } from '../../services/equipofolio.service';
import { Evaluacion } from '../../models/evaluacion';
import { FolioClienteService } from '../../services/foliocliente.service';
import { Cliente } from '../../models/cliente';

declare var $:any;

@Component({
    selector:'evaluacion-token',
    templateUrl:'./evaluacion-token.component.html',
    providers:[UsuarioService,EncuestaAreaService,EvaluacionService,EvaluacionDetalleService,FolioAreaService,HelperService,UsuarioService,EquipoFolioService,FolioClienteService]
})

export class EvaluacionTokenComponent implements OnInit{
    public title:string;
    public identity;
    public encuestaarea:EncuestaArea;
    public encuestaareatemp:EncuestaArea;
    public status:string;
    public errorTexto:string;
    public preguntas: Pregunta[];
    public pregunta:Pregunta;
    public usuario:Usuario;
    public evaluaciondetalle:EvaluacionDetalle;
    public respuesta:Respuesta;
    public reconocimiento:Reconocimiento;
    public reconocimiento2: Reconocimiento[];

    public encuestaareastorage;
    public evaluacionstorage;

    public pregunta_ultima;

    public url: string;

    public contador:number;

    public cuestionarioCompleto:boolean;
    public token:string;
    public tokenurl:string;
    public evaluacion:Evaluacion;
    public evaluacionid:number;
    public cliente:Cliente; 

    constructor(
        private _usuarioService:UsuarioService,
        private _encuestaareaservice:EncuestaAreaService,
        private _evaluacionservice:EvaluacionService,
        private _evaluaciondetalleService:EvaluacionDetalleService,
        private _router:Router,
        private _route:ActivatedRoute,
        private _folioareaservice: FolioAreaService,
        private _helperservice: HelperService,
        private _usuarioservice:UsuarioService,
        private _equipofolioservice:EquipoFolioService,
        private _folioclienteservice:FolioClienteService
    ){
        this.title = 'Bienvenido a la evaluación del servicio';
        this.url = GLOBAL.url;
    }

    clickReconocimiento(reconocimiento){
        var reconocimientoId = reconocimiento.ID;
        console.log("entre a clickreconocimiento");
        console.log("this.evaluacionlocalstorage: " + JSON.stringify(this.evaluacionstorage));
        console.log("log de reconocimiento click: " + JSON.stringify(reconocimiento));
        //this.evaluaciondetalle = new EvaluacionDetalle(0,this.evaluacionstorage,this.pregunta,this.respuesta,reconocimiento,this.encuestaareastorage,new Date(),new Date(),new Date(), true, new Date(), new Date(), 'WEB_MASTER');

        this.evaluaciondetalle = new EvaluacionDetalle();
        this.evaluaciondetalle.ID_EVALUACION = this.evaluacionid;
        this.evaluaciondetalle.Pregunta = this.pregunta;
        this.evaluaciondetalle.Respuesta = this.respuesta;
        this.evaluaciondetalle.Reconocimiento = reconocimiento;
        this.evaluaciondetalle.FEC_RECONOCIMIENTO = new Date();

        console.log("log de this.evaluaciondetalle modificar: " + JSON.stringify(this.evaluaciondetalle));
        this.evaluacionDetalleAsignarReconocimiento(this.evaluaciondetalle,this.token);

        $('#myModal').modal('hide');

        //$("#fuelux-wizard-container").wizard('next');
    }

    //https://stackoverflow.com/questions/36006894/angular2-get-clicked-element-id

    wizarClick(event, pregunta, respuesta) 
    {
        console.log("log de wizardClick : " + this.evaluacionid);
        var tieneReconocimiento;
        this.pregunta = pregunta;
        this.pregunta_ultima = this.preguntas[this.preguntas.length-1];
        this.respuesta = respuesta;
        this.reconocimiento2 = pregunta.Reconocimientos.filter(x => x.BANDERA === this.respuesta.BANDERA);         //this.menuFiltrado = menu.find(x => x.PADRE== idMenu);
        //this.reconocimiento = new Reconocimiento(null,"","",true,new Date(),true,new Date(),new Date(),"");
        //this.encuestaareatemp = this.encuestaareastorage;
        //var ID_ENCUESTA_AREA = pregunta.EncuestaAreaPregunta.ID_ENCUESTA_AREA;
        //this.evaluaciondetalle = new EvaluacionDetalle(0,this.evaluacionstorage,this.pregunta,this.respuesta,this.reconocimiento,this.encuestaareatemp,new Date(),null,new Date(), true, new Date(), new Date(), 'WEB_MASTER');
        
        this.evaluaciondetalle = new EvaluacionDetalle();

        this.evaluaciondetalle.ID_EVALUACION = this.evaluacionid;
        this.evaluaciondetalle.Pregunta = this.pregunta;
        this.evaluaciondetalle.Respuesta = this.respuesta;
        this.evaluaciondetalle.FEC_REG = new Date();
        this.evaluaciondetalle.FEC_RECONOCIMIENTO = null;
        this.evaluaciondetalle.FEC_PREGUNTA = new Date();
        this.evaluaciondetalle.ESTATUS = true;
        this.evaluaciondetalle.FECHA_MOD = new Date();
        this.evaluaciondetalle.HHMMSS_MOD = new Date();

        //this.evaluacionDetalleGuardar(this.evaluaciondetalle);
        //console.log("datos de evaluacion cliente al responder");
        //console.log(JSON.stringify(this.evaluaciondetalle));
        console.log("log de evaluaciondetalle: " + JSON.stringify(this.evaluaciondetalle));
        this.validarDetalleExiste(this.evaluaciondetalle,this.token);

        tieneReconocimiento = pregunta.EncuestaAreaPregunta.TIENE_RECONOCIMIENTO;

        if(tieneReconocimiento){
            $('#myModal').modal({backdrop: 'static', keyboard: false, show:true})
        }

        if(this.pregunta.ID==this.pregunta_ultima.ID){
            $('#'+this.pregunta.ID).addClass("complete");
            this.cuestionarioCompleto=true;
            setTimeout(() => {
                this.terminarEvaluacion();
            }, 15000);  //15s
        }
        else{
            $("#fuelux-wizard-container").wizard('next');
        }
    }

    //valida si existe el registro, si existe lo modifica sino lo agrega
    validarDetalleExiste(evaluaciondetalle,token){
        this._evaluaciondetalleService.evaluacionDetalleExiste(evaluaciondetalle,token).subscribe(
            response =>{
                /*if(response.item==null){
                    //la respuesta no existe entonces procedemos a insertarla
                    this.evaluacionDetalleGuardar(evaluaciondetalle);
                }
                else{
                    //la respuesta existe entonces procedemos a modificarla
                    this.evaluacionDetalleModificar(evaluaciondetalle);
                }*/
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    evaluacionDetalleAsignarReconocimiento(evaluaciondetalle,token){
        this._evaluaciondetalleService.evaluacionDetalleAsignarReconocimiento(evaluaciondetalle,token).subscribe(
            response =>{
                console.log("evaluacion detalle modificar con exito");
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    ngOnInit(){
        console.log("Componente evaluacion token cargado correctamente");
        this.token = this._usuarioservice.getToken();
        this.validarAcceso(this.token);
        //tomamos los datos del folio recibido
        this.tokenurl = this._route.snapshot.paramMap.get('TOKEN');
        //validamos que el folio sea valido.
        this.validarToken(this.tokenurl,this.token);
        //this.evaluacionstorage = this._evaluacionservice.getEvaluacionStorage();
        //this.encuestaareastorage = this._encuestaareaservice.getEncuestaAreaStorage();
        /*this.getEncuesta();
        this.contador=0;
        this.cuestionarioCompleto=false;
        this.actualizarEstatusFolioArea();*/
    }

    obtenerClientePorTokenCabecera(tokenurl,token){
        this._folioclienteservice.obtenerClientePorTokenCabecera(tokenurl,token).subscribe(
            response=>{
                this.cliente = response.item;
                console.log(tokenurl);
                console.log(JSON.stringify(this.cliente));
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    validarAcceso(token){
        this._helperservice.accesoToken(token).subscribe(
            response=>{
                console.log("LOG TOKEN VALIDO");
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        );
    }

    validarToken(tokenurl,token){
        console.log("ENTRE A METODO VALIDAR FOLIO");
        this._equipofolioservice.validarToken(tokenurl,token).subscribe(
            response=>{
                if(response.item == null){
                    this.redireccionar("404");
                }
                else{
                    //creamos o no, la evaluación
                    console.log("token validado correctamente");
                    this.crearEvaluacion(tokenurl,token);
                    this.actualizarEstatusFolioAreaToken(token); 
                    /*this.crearEvaluacion(this.foliourl,this.token);
                    this.actualizarEstatusFolioArea(folio,token); */
                }
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    crearEvaluacion(tokenurl,token){

        this.evaluacion = new Evaluacion(0);
        this.evaluacion.FEC_FIN = new Date();
        this.evaluacion.FOLIO = ""; //TOMAR EL FOLIO DE LA URL
        this.evaluacion.FEC_REG = new Date();
        this.evaluacion.ESTATUS = true;
        this.evaluacion.FECHA_MOD = new Date();
        this.evaluacion.HHMMSS_MOD = new Date();

        this._evaluacionservice.evaluacionAgregar(this.evaluacion,token).subscribe(
            response=>{
                console.log("ENCUESTRA CREADA CORRECTAMENTE");
                this.evaluacionid = response.item.ID;
                this.getEncuesta(this.evaluacionid,this.token);
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    getEncuesta(evaluacionid,token){
        //console.log("ng on init ID EVALUACION GUARDADA: " + JSON.stringify(this.evaluacionGuardada.ID));
        this._encuestaareaservice.encuestaMostrarToken(evaluacionid,token).subscribe(
            response =>{
                this.preguntas = response.preguntas; //[response.items[0].Pregunta,response.items[1].Pregunta];
                this.obtenerClientePorTokenCabecera(this.tokenurl,token);
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    //actualiza el estatus a contestado por FOLIO e ID_AREA
    actualizarEstatusFolioAreaToken(token){
        this._folioareaservice.contestarFolioAreaToken(token).subscribe(
            response =>{
                console.log("folio actualizado correctamente");
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    } 

    terminarEvaluacion(){
        localStorage.clear();
        this.identity=null;
        window.location.href="https://planlealtad.laclicsa.com.mx/";
        //this.redireccionar('https://www.laclicsa.com.mx/');
    }

    redireccionar(ruta){
        this._router.navigate([ruta]);
    }

}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { EncuestaArea } from '../models/encuestaarea'; //
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class EncuestaAreaService{

    public url:string; //url de nuestro backend
    public encuestaArea:string;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    encuestaMostrar(ID_EVALUACION:number, FOLIO:string,token:string): Observable<any>{

        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //var jsonCompuesto = { ID: ID, ID_EMPRESA: ID_EMPRESA, ORDEN:ORDEN, ID_EVALUACION:ID_EVALUACION, FOLIO:FOLIO }
        //let params = JSON.stringify(jsonCompuesto);

        let params = JSON.stringify({ID_EVALUACION:ID_EVALUACION, FOLIO:FOLIO});

        return this._http.post(this.url+'encuesta-por-area',params, {headers:headers});
    }

    encuestaMostrarToken(ID_EVALUACION:number,token:string): Observable<any>{

        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        let params = JSON.stringify({ID_EVALUACION:ID_EVALUACION});

        return this._http.post(this.url+'encuesta-por-area-token',params, {headers:headers});
    }

    obtenerEncuestaAreaPorTerminal(ID:number): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');

        let params = JSON.stringify(ID);

        console.log("TERMINAL_ID " + JSON.stringify(ID));

        return this._http.post(this.url+'encuestaarea-obtener-terminal/' + params, {headers:headers});
    }

    getEncuestaAreaStorage(){
        let encuestaArea = JSON.parse(localStorage.getItem('encuestaarea'));

        if(encuestaArea!="undefined"){
            this.encuestaArea = encuestaArea;
        }
        else{
            this.encuestaArea = null;
        }

        return encuestaArea;
    }

    //trae la encuesta area filtrada por ID_AREA, ID_EMPRESA y ESTATUS=1
    encuestaAreaPorEmpresaArea(ID_AREA:number,ID_EMPRESA:number):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');

        /*let body = new HttpParams();
        body = body.set('ID_AREA',ID_AREA.toString());
        body = body.set('ID_EMPRESA',ID_EMPRESA.toString());*/

        var jsonCompuesto = { ID_AREA: ID_AREA, ID_EMPRESA: ID_EMPRESA }
        let params = JSON.stringify(jsonCompuesto);

        return this._http.post(this.url+'encuestaarea-obtener-area-empresa/', params,{headers:headers});
    }

        //trae la encuesta area filtrada por IID_EMPRESA despues de haber validado antes si el token es valido
        encuestaAreaPorIdEmpresa(ID_EMPRESA:number):Observable<any>{
            let headers = new HttpHeaders().set('Content-Type','application/json');
    
            return this._http.post(this.url+'encuestarea-obtener-token-por-idempresa/'+ ID_EMPRESA,{headers:headers});
        }

    
        GRIDlistar(token:string): Observable<any>{
            let headers = new HttpHeaders().set('Content-Type','application/json')
                                           .set('Authorization',token);
    
            return this._http.get(this.url+'GRID-listar-encuestaareas', {headers:headers});
        }

    eliminar(ID_ENCUESTA_AREA:Number,token:string): Observable<any>{
        let params = JSON.stringify({ID_ENCUESTA_AREA:ID_ENCUESTA_AREA});
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                        .set('Authorization',token);

            return this._http.put(this.url+'encuestaarea-eliminar',params, {headers:headers});
    }

    gestion(item: EncuestaArea,token:string): Observable<any>{
        let params = JSON.stringify(item);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

            return this._http.post(this.url+'encuestaarea-agregar',params, {headers:headers});
    }

    obtenerAreasDeEncuesta(ID_ENCUESTA:number,ID_EMPRESA:number,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});
        //console.log("valor de id_pregunta: " + ID_PREGUNTA);

        return this._http.get(this.url+'obtener-areas-de-encuesta/'+ID_ENCUESTA+'/'+ID_EMPRESA,{headers:headers});
    }

    obtenerEmpresasActivasDeEncuestaArea(token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});
        //console.log("valor de id_pregunta: " + ID_PREGUNTA);

        return this._http.get(this.url+'EA-obtener-empresa',{headers:headers});
    }

    obtenerEncuestasActivasDeEncuestaArea(ID_EMPRESA:number,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});
        //console.log("valor de id_pregunta: " + ID_PREGUNTA);

        return this._http.get(this.url+'EA-obtener-encuesta/' + ID_EMPRESA,{headers:headers});
    }

    obtenerAreasActivasDeEncuestaArea(ID_ENCUESTA:number,ID_EMPRESA:number,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});
        //console.log("valor de id_pregunta: " + ID_PREGUNTA);

        return this._http.get(this.url+'EA-obtener-area/'+ID_ENCUESTA+'/'+ID_EMPRESA,{headers:headers});
    }
    
}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { Usuario } from '../models/usuario'; //
import { GLOBAL } from './global'; // la url del API
import { Router } from '@angular/router';
import { Sucursal } from '../models/sucursal';
import { Area } from '../models/area';

@Injectable()
export class UsuarioService{
    public url:string; //url de nuestro backend
    public identity;
    public token;

    constructor(public _http:HttpClient,private _router:Router,){
        this.url = GLOBAL.url;
    }

    identificarUsuario(usuario: Usuario): Observable<any>{

        //hay que checar la parte de los tokens
        let params = JSON.stringify(usuario);
        let headers = new HttpHeaders().set('Content-Type','application/json');

        return this._http.post(this.url+'login',params, {headers:headers});
    }

    identificarToken(URLTOKEN:string): Observable<any>{
        let params = JSON.stringify({URLTOKEN :URLTOKEN});
        let headers = new HttpHeaders().set('Content-Type','application/json');

        return this._http.post(this.url+'login-token',params, {headers:headers});
    }

    obtenerInformacionIdentity(token:string):Observable<any>{
        console.log("obtener identity");
        console.log(token);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'get-identity',null,{headers:headers});
    }

    obtenerInformacionIdentityToken(token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'get-identity-token',null,{headers:headers});
    }

    obtenerAreasLogin():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');

        return this._http.get(this.url+'obtener-areas-login',{headers:headers});
    }

    obtenerSucursalesLogin():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');

        return this._http.get(this.url+'obtener-sucursales-login',{headers:headers});
    }

    getIdentity(){
        let identity = JSON.parse(localStorage.getItem('identity'));

        if(identity!="undefined"){
            this.identity = identity;
        }
        else
        {
            this.identity = null;
        }
        return this.identity;
    }

    getToken(){
        let token = localStorage.getItem('token');

        if(token!="undefined"){
            this.token = token;
        }
        else{
            this.token = null;
        }
        return token;
    }

    isLoggedIn(){
        if(localStorage.getItem('token') != null){
            return true;
        }
        else
        {
            return false;
        }
    }

        /* CATALOGO */

        GRIDlistar(token:string): Observable<any>{
            let headers = new HttpHeaders().set('Content-Type','application/json')
                                           .set('Authorization',token);
    
            return this._http.get(this.url+'GRID-listar-usuarios', {headers:headers});
        }
    
        /* FUNCIONES */
    
        /**************************************/
    
        gestion(item: Usuario,token:string): Observable<any>{
            let params = JSON.stringify(item);
            let headers = new HttpHeaders().set('Content-Type','application/json')
                                           .set('Authorization',token);
    
            if(item.ID==0){
                return this._http.post(this.url+'usuario-agregar',params, {headers:headers});
            }
            else{
                return this._http.put(this.url+'usuario-modificar',params,{headers:headers});
            }
        }
}
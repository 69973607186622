import { EncuestaArea } from './encuestaarea';
import { Pregunta } from './pregunta';
import { Encuesta } from './encuesta';
import { Empresa } from './empresa';

export class EncuestaAreaPregunta{
    constructor(
        public ID:number,
        public EncuestaArea:EncuestaArea,
        public Pregunta:Pregunta,
        public ORDEN:number,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string,
        public TIENE_RECONOCIMIENTO:boolean
    ){

    }
}

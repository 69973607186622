import { Tipo } from './tipo';

export class Area{

    //public testa:string;
    public ID:number;
    public DESCRIPCION:string;
    public ID_TIPO:number;
    public Tipo:Tipo;
    public FEC_REG:Date;
    public ESTATUS:boolean;
    public FECHA_MOD:Date;
    public HHMMSS_MOD:Date;
    public CVE_USU_MOD:string;

    constructor(

    ){
        this.Tipo = new Tipo();
    }
}
import { Terminal } from './terminal';
import { Sucursal } from './sucursal'

export class Equipo{

    public ID:number;
    public DESCRIPCION: string;
    public ID_SUCURSAL:number;
    public Sucursal:Sucursal;
    public ID_TERMINAL:Number;
    public Terminal:Terminal;
    public NUM_SERIE:string;
    public FEC_REG:Date;
    public ESTATUS:boolean;
    public FECHA_MOD:Date;
    public HHMMSS_MOD:Date;
    public CVE_USU_MOD:string;

    constructor(
    ){
        this.Sucursal = new Sucursal();
        this.Terminal = new Terminal();
    }
}

import { Component, OnInit, ɵConsole } from '@angular/core';
import { UsuarioService} from '../../services/usuario.service';
import { EncuestaAreaService } from '../../services/encuestaarea.service';
import { Pregunta } from '../../models/pregunta';
import { EvaluacionService } from '../../services/evaluacion.service';
import { EvaluacionDetalleService } from '../../services/evaluaciondetalle.service';
import { EvaluacionDetalle } from '../../models/evaluaciondetalle';
import { GLOBAL } from '../../services/global';
import { Respuesta } from 'src/app/models/respuesta';
import { Reconocimiento } from 'src/app/models/reconocimiento';
import { Router, ActivatedRoute } from '@angular/router';
import { FolioAreaService } from '../../services/folioarea.service';
import { HelperService } from '../../services/helpers.service';
import { Evaluacion } from '../../models/evaluacion';
import { EquipoFolioService } from '../../services/equipofolio.service';
import { TipoService } from '../../services/tipo.service';
import { FolioClienteService } from '../../services/foliocliente.service';
import { Cliente } from '../../models/cliente';

declare var $:any;

@Component({
    selector:'evaluacion',
    templateUrl:'./evaluacion.component.html',
    providers:[UsuarioService,EncuestaAreaService,EvaluacionService,EvaluacionDetalleService,FolioAreaService,HelperService,EquipoFolioService,TipoService,FolioClienteService]
})

export class EvaluacionComponent implements OnInit{
    public title:string;
    public identity;
    public status:string;
    public errorTexto:string;
    public preguntas: Pregunta[];
    public pregunta:Pregunta;
    public evaluaciondetalle:EvaluacionDetalle;
    public respuesta:Respuesta;
    public reconocimiento2: Reconocimiento[];
    public pregunta_ultima;
    public url: string;
    public cuestionarioCompleto:boolean;
    public token; // objeto del token
    public evaluacion:Evaluacion;
    public foliourl:string;
    public evaluacionid:number;
    public cliente:Cliente;
    public clienteCabecera:Cliente;
    public valortest:string;
    public urlpararedireccionar:string;

    constructor(
        private _usuarioservice:UsuarioService,
        private _encuestaareaservice:EncuestaAreaService,
        private _evaluacionservice:EvaluacionService,
        private _evaluaciondetalleService:EvaluacionDetalleService,
        private _router:Router,
        private _route:ActivatedRoute,
        private _folioareaservice:FolioAreaService,
        private _helperservice:HelperService,
        private _equipofolioservice:EquipoFolioService,
        private _tiposervice:TipoService,
        private _folioclienteservice:FolioClienteService
    ){
        this.title = 'Bienvenido a la evaluación del servicio';
        this.url = GLOBAL.url;
    }

    clickReconocimiento(reconocimiento){
        this.evaluaciondetalle = new EvaluacionDetalle();
        this.evaluaciondetalle.ID_EVALUACION = this.evaluacionid;
        this.evaluaciondetalle.Pregunta = this.pregunta;
        this.evaluaciondetalle.Respuesta = this.respuesta;
        this.evaluaciondetalle.Reconocimiento = reconocimiento;
        this.evaluaciondetalle.FEC_RECONOCIMIENTO = new Date();
        this.evaluacionDetalleAsignarReconocimiento(this.evaluaciondetalle,this.token);

        $('#myModal').modal('hide');
    }

    //https://stackoverflow.com/questions/36006894/angular2-get-clicked-element-id

    wizarClick(event, pregunta, respuesta) 
    {
        console.log("log de wizardClick : " + this.evaluacionid);
        console.log("lod de cliente cabecera: " + JSON.stringify(this.clienteCabecera));
        var tieneReconocimiento;
        this.pregunta = pregunta;
        this.pregunta_ultima = this.preguntas[this.preguntas.length-1];
        this.respuesta = respuesta;
        this.reconocimiento2 = pregunta.Reconocimientos.filter(x => x.BANDERA === this.respuesta.BANDERA);         //this.menuFiltrado = menu.find(x => x.PADRE== idMenu);
        this.evaluaciondetalle = new EvaluacionDetalle();
        this.evaluaciondetalle.ID_EVALUACION = this.evaluacionid;
        this.evaluaciondetalle.Pregunta = this.pregunta;
        this.evaluaciondetalle.Respuesta = this.respuesta;
        this.evaluaciondetalle.FEC_REG = new Date();
        this.evaluaciondetalle.FEC_RECONOCIMIENTO = null;
        this.evaluaciondetalle.FEC_PREGUNTA = new Date();
        this.evaluaciondetalle.CVE_COL = this.clienteCabecera.CVE_COL;
        this.evaluaciondetalle.ESTATUS = true;
        this.evaluaciondetalle.FECHA_MOD = new Date();
        this.evaluaciondetalle.HHMMSS_MOD = new Date();
        //console.log(JSON.stringify(this.evaluaciondetalle));
        console.log("log de encuesta_area: " + JSON.stringify(pregunta));
        this.validarDetalleExiste(this.evaluaciondetalle,this.token);

        tieneReconocimiento = pregunta.EncuestaAreaPregunta.TIENE_RECONOCIMIENTO;

        if(tieneReconocimiento){
            $('#myModal').modal({backdrop: 'static', keyboard: false, show:true})
        }

        if(this.pregunta.ID==this.pregunta_ultima.ID){
            $('#'+this.pregunta.ID).addClass("complete");
            this.cuestionarioCompleto=true;
            /*setTimeout(() => {
                $('#myModal').modal('hide');
                this.terminarEvaluacion();
            }, 15000);  //15s*/
        }
        else{
            $("#fuelux-wizard-container").wizard('next');
        }

        console.log(JSON.stringify(this.cliente));
    }

    //valida si existe el registro, si existe lo modifica sino lo agrega
    validarDetalleExiste(evaluaciondetalle,token){
        this._evaluaciondetalleService.evaluacionDetalleExiste(evaluaciondetalle,token).subscribe(
            response =>{
                this.cliente = response.item;
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    evaluacionDetalleAsignarReconocimiento(evaluaciondetalle,token){
        this._evaluaciondetalleService.evaluacionDetalleAsignarReconocimiento(evaluaciondetalle,token).subscribe(
            response =>{
                console.log("evaluacion detalle modificar con exito");
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    ngOnInit(){
        console.log('Componente home cargado');
        this.token = this._usuarioservice.getToken();
        //primero validamos que el acceso sea valido
        this.validarAcceso(this.token);
        //tomamos los datos del folio recibido
        this.foliourl = this._route.snapshot.paramMap.get('FOLIO');
        //validamos que el folio sea valido.
        this.validarFolio(this.foliourl,this.token);
        this.cuestionarioCompleto=false;
        //this.valortest = "edwin j";
        //desde
        this.contadorTerminarEvaluacion();
    }

    obtenerClientePorFolioCabecera(folio,token){
        this._folioclienteservice.obtenerClientePorFolioCabecera(folio,token).subscribe(
            response=>{
                this.clienteCabecera = response.item;
                console.log(JSON.stringify(this.clienteCabecera));
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    validarAcceso(token){
        this._helperservice.acceso(token).subscribe(
            response=>{
                console.log("LOG TOKEN VALIDO");
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        );
    }

    validarFolio(folio,token){
        console.log("ENTRE A METODO VALIDAR FOLIO");
        this._equipofolioservice.validarFolio(folio,token).subscribe(
            response=>{
                if(response.item == null){
                    //this.redireccionar("404");
                    this.terminarEvaluacion();
                }
                else{
                    //creamos o no, la evaluación
                    this.crearEvaluacion(this.foliourl,this.token);
                    this.actualizarEstatusFolioArea(folio,token); 
                }
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    crearEvaluacion(folio,token){

        this.evaluacion = new Evaluacion(0);
        this.evaluacion.FEC_FIN = new Date();
        this.evaluacion.FOLIO = folio; //TOMAR EL FOLIO DE LA URL
        this.evaluacion.FEC_REG = new Date();
        this.evaluacion.ESTATUS = true;
        this.evaluacion.FECHA_MOD = new Date();
        this.evaluacion.HHMMSS_MOD = new Date();

        this._evaluacionservice.evaluacionAgregar(this.evaluacion,token).subscribe(
            response=>{
                this.evaluacionid = response.item.ID;
                this.getEncuesta(this.evaluacionid,this.foliourl,this.token);
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    //actualiza el estatus a contestado por FOLIO e ID_AREA
    actualizarEstatusFolioArea(folio,token){
        this._folioareaservice.contestarFolioArea(folio,token).subscribe(
            response =>{
                console.log("folio actualizado correctamente");
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    } 

    getEncuesta(evaluacionid,FOLIO,token){
        this._encuestaareaservice.encuestaMostrar(evaluacionid,FOLIO,token).subscribe(
            response =>{
                this.preguntas = response.preguntas; //[response.items[0].Pregunta,response.items[1].Pregunta];
                this.obtenerClientePorFolioCabecera(FOLIO,token);
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    //https://stackblitz.com/edit/timer-with-pause?file=src%2Fapp%2Fapp.component.ts

    terminarEvaluacion(){
        this._tiposervice.obtenerUrl(this.token).subscribe(
            response=>{
                this.redireccionar(response.item.URL_TERMINO_EVALUACION);
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    interval;
    timeLeft:number;

    contadorTerminarEvaluacion()
    {
        this.timeLeft=60;
        this._tiposervice.obtenerUrl(this.token).subscribe(
            response=>{
                this.urlpararedireccionar = response.item.URL_TERMINO_EVALUACION
                console.log(JSON.stringify(this.urlpararedireccionar));  
                //this.redireccionar(response.item.URL_TERMINO_EVALUACION);
                if(this.urlpararedireccionar.includes('recepcion')){
                    this.interval = setInterval(()=>{
                        if(this.timeLeft<=0){
                            $('#myModal').modal('hide');
                            this.terminarEvaluacion();
                            //console.log("se redirecciona la evaluacion");
                        }
                        else{
                            this.timeLeft--;
                            console.log(this.timeLeft);
                        }
                    },1000)
                }
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    private ngOnDestroy(): void {
		clearInterval(this.interval);
}

    redireccionar(ruta){
        this._router.navigate([ruta]);
    }
}
import { Component, OnInit, ɵConsole } from '@angular/core';
import { UsuarioService} from '../../services/usuario.service';
import { EncuestaAreaService } from '../../services/encuestaarea.service';
import { ClienteService } from '../../services/cliente.service';
import { Cliente } from '../../models/cliente';
import { EncuestaArea } from '../../models/encuestaarea';
import { Pregunta } from '../../models/pregunta';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { Router, ActivatedRoute } from '@angular/router';

import { GLOBAL } from '../../services/global';

import { DatePipe } from '@angular/common';

import { Evaluacion } from '../../models/evaluacion';
import { EvaluacionService } from "../../services/evaluacion.service";
import { Encuesta } from 'src/app/models/encuesta';
import { TipoCliente } from 'src/app/models/tipocliente';
import { Sucursal } from 'src/app/models/sucursal';

import { EquipoFolioService } from '../../services/equipofolio.service';
import { HelperService } from '../../services/helpers.service';

import { FolioAreaService } from '../../services/folioarea.service'


@Component({
    selector:'verificar-datos-token',
    templateUrl:'verificar-datos-token.component.html',
    providers:[UsuarioService,EncuestaAreaService, ClienteService,EvaluacionService,EquipoFolioService,HelperService,FolioAreaService]
})

export class VerificarDatosTokenComponent implements OnInit{

    public title:string;
    public identity;
    public encuestaarea:EncuestaArea;
    public status:string;
    public errorTexto:string;
    public preguntas:Pregunta[];
    public folio:string;
    public cliente: Cliente;
    public tipoCliente: TipoCliente;
    public sucursal: Sucursal;
    public folioLocalStorage;
    public url: string;
    public evaluacion: Evaluacion;
    public encuesta: Encuesta;
    public encuestaAreaToken;
    public estudios=[];
    public token;
    fromDate;
    urltoken:string;
    public ID_EMPRESA:any;

    constructor(
        private _route:ActivatedRoute,
        private _router:Router,
        private _usuarioService:UsuarioService,
        private _clienteservice:ClienteService,
        private _evaluacionservice:EvaluacionService,
        private _encuestaareaservice:EncuestaAreaService,
        private datePipe: DatePipe,
        private _equipofolioservice:EquipoFolioService,
        private _helperservice:HelperService,
        private _folioareaservice:FolioAreaService
        ){
        this.title = 'Verificar datos entre sin login';
        this.url = GLOBAL.url;
    }

    ngOnInit(){
        console.log("componente verificar datos cargado");
        localStorage.clear();
        this.urltoken = this._route.snapshot.paramMap.get('token');
        console.log(this.urltoken);
        this.login(this.urltoken);
        /*this.validarToken(this.urltoken);*/
        //validamos si es una url valida
        //validamos si es un token valido
        //traemos los datos del cliente
    }

    login(token){
        this._usuarioService.identificarToken(token).subscribe(
            response =>{
                //si el usuario existe asignamos el token generado al localStorage
                localStorage.setItem('token',JSON.stringify(response.token));
                console.log("proceso realizado correctaente");
                this.obtenerInformacionIdentityToken(response.token);
                this.obtenerClientePorToken(response.token);
            },
            error=>{
                this.redireccionar("404");
            }
        );
    }

    //trae la información del usuario para mostrar en la página
    obtenerInformacionIdentityToken(token){
        console.log(token);
        this._usuarioService.obtenerInformacionIdentityToken(token).subscribe(
            response =>{
                console.log("proceso realizado correctamente: obtenerInformacionIdentity");
                console.log(JSON.stringify(response.item));
                //si el usuario existe asignamos el identity generado al localStorage
                localStorage.setItem('identity',JSON.stringify(response.item[0]));
                this.identity = this._usuarioService.getIdentity();
                //this.redireccionar();
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        )
    }

    validarToken(token){
        this._equipofolioservice.validarToken("",token).subscribe(
            response => {
                if(response.item!=null){
                    //this.obtenerencuestaArea(response.item.Equipo.Sucursal.ID_EMPRESA);
                    //this.obtenerClientePorFolio(response.item.FOLIO);
                    this.ID_EMPRESA = response.item.Equipo.Sucursal.ID_EMPRESA;
                    this.validarFolioContestado(response.item.FOLIO);
                    this.sucursal = response.item.Equipo.Sucursal;
                }
                else{

                }
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    validarFolioContestado(FOLIO){

        this._folioareaservice.obtenerFolioAreaEntregaResultaddos(FOLIO).subscribe(
            response =>{
                if(response.result){
                    this.obtenerClientePorToken(FOLIO);
                }
                else{
                    console.log("el folio ya fue contestado");
                }
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        );
    }

    obtenerClientePorToken(token){
        //loguear al usuario y conseguir sus datos
        this._clienteservice.obtenerClientePorToken(token).subscribe( 
        response =>{
        //if(Object.keys(response.item).length === 0){
        if(response.item==null){
            this.cliente = null;
        }
        else{
            console.log("son iguales");
            this.cliente = response.item;

            this.status = 'success';
            //console.log("log de cliente: " + JSON.stringify(this.cliente));
            this.fromDate = this.datePipe.transform(this.cliente.FEC_NAC, 'yyyy-MM-dd');

            console.log("log de fecha de nacimiento: " + this.fromDate)
            this.obtenerEstudiosPorFolio(token);
        }
    },
    err =>{
        var errorMessage = <any>err;
        console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
        this.errorTexto = errorMessage.error.message;
        if(errorMessage !=null){
            this.status = 'error';
        }
    }
);
}

    obtenerEstudiosPorFolio(token){
    this._helperservice.obtenerEstudiosPorFolio("",token).subscribe(
        response =>{
            console.log("LOG DE OBTENERESTUDIOSPORFOLIO")
            this.estudios = response.item;
            console.log("log de estudios: " + JSON.stringify(this.estudios));
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        })
    };

    irEvaluacion(){
        var url = "evaluacion-token/" + this.urltoken;
        this.redireccionar(url); 
    }

    redireccionar(url){
        this._router.navigate([url]);
    }
}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { Empresa } from '../models/empresa'; //
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class HelperService{
    public url:string; //url de nuestro backend

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    acceso(token:string){
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'acceso',{headers:headers});
    }

    accesoToken(token:string){
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'acceso-token',{headers:headers});
    }

    obtenerEstudiosPorFolio(FOLIO:string,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);
        
        var params = JSON.stringify({ FOLIO: FOLIO });

        return this._http.post(this.url+'obtener-estudios-folio',params,{headers:headers});
    }

    obtenerEstudiosToken_(token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'obtener-estudios-token',null,{headers:headers});
    }

    obtenerTomaMuestraPorFolio(folio:string,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'obtener-tipo-muestra/'+folio,{headers:headers});
    }

    obtenerUsuarioPorCVE_USU(CVE_USU:string,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'obtener-usuario-CVEUSU/'+CVE_USU,{headers:headers});
    }
}
import { Encuesta } from './encuesta';
import { Sucursal } from './sucursal';
import { Evaluacion } from './evaluacion';
import { Pregunta } from './pregunta';
import { Respuesta } from './respuesta';

export class Reconocimiento{
    constructor(
        public ID:number,
        public DESCRIPCION: String,
        public IMAGEN: String,
        public BANDERA: Boolean,
        public FEC_REG: Date,
        public ESTATUS: Boolean,
        public FECHA_MOD: Date,
        public HHMMSS_MOD: Date,
        public CVE_USU_MOD: String
    ){

    }
}
import { Pregunta } from './pregunta';
import { Reconocimiento } from './reconocimiento';

export class PreguntaReconocimiento{
    constructor(
        public ID:number,
        public ID_PREGUNTA:number,
        public Pregunta:Pregunta,
        public ID_RECONOCIMIENTO:number,
        public Reconocimiento:Reconocimiento,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string
    ){

    }
}

import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { Cliente } from '../models/cliente'; //
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class FolioAreaService{
    public url:string; //url de nuestro backend

    public folioLocalStorage;
    public EquipoTerminalToken;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    //sirve para validar que los folios hayan sido o no contestado
    obtenerFolioArea(FOLIO:string, ID_AREA: number ):Observable<any>{
        console.log("entre al metodo obtenerFolioArea");
        //hay que checar la parte de los tokens
        var folioJSON = { FOLIO: FOLIO, ID_AREA: ID_AREA }
        let params = JSON.stringify(folioJSON);
        let headers = new HttpHeaders().set('Content-Type','application/json');
        //console.log("valor de los parametros: " + params);
        return this._http.post(this.url+'obtener-folio-area',params, {headers:headers});
    }

    //sirve para validar que los folios hayan sido o no contestado
    obtenerFolioAreaEntregaResultaddos(FOLIO:string):Observable<any>{
        console.log("entre al metodo obtenerFolioArea");
        //hay que checar la parte de los tokens
        let headers = new HttpHeaders().set('Content-Type','application/json');
        //console.log("valor de los parametros: " + params);
        return this._http.post(this.url+'obtener-folio-area-entrega-resultados/'+ FOLIO, {headers:headers});
    }

    contestarFolioArea(FOLIO:string,token:string):Observable<any>{
        let params = JSON.stringify({ FOLIO: FOLIO });
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'contestar-folioarea',params,{headers:headers});
    }

    contestarFolioAreaToken(token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'contestar-folioarea-token',null,{headers:headers});
    }

    asignarColaborador(folio:string,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        let params = JSON.stringify({FOLIO:folio});

        return this._http.put(this.url+'asignar-colaborador-folio-area',params,{headers:headers});
    }
}
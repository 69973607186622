import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { EvaluacionDetalle } from '../models/evaluaciondetalle'; //
import { GLOBAL } from './global'; // la url del API


@Injectable()
export class EvaluacionDetalleService{
    public url:string; //url de nuestro backend
    public evaluacion:string;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    evaluacionDetalleAgregar_(evaluaciondetalle: EvaluacionDetalle): Observable<any>{
        //hay que checar la parte de los tokens
        let params = JSON.stringify(evaluaciondetalle);
        console.log("entre al log de evaluacionDetalleAgregar");
        console.log(JSON.stringify(evaluaciondetalle));
        let headers = new HttpHeaders().set('Content-Type','application/json');

        return this._http.post(this.url+'evaluacion-detalle-agregar',params, {headers:headers});
    }

    evaluacionDetalleModificar_(evaluaciondetalle: EvaluacionDetalle): Observable<any>{
        //hay que checar la parte de los tokens
        let params = JSON.stringify(evaluaciondetalle);
        console.log("entre al log de evaluacionDetalleModificar");
        console.log(JSON.stringify(evaluaciondetalle));
        let headers = new HttpHeaders().set('Content-Type','application/json');

        return this._http.post(this.url+'evaluacion-detalle-modificar',params, {headers:headers});
    }

    evaluacionDetalleExiste(evaluaciondetalle: EvaluacionDetalle,token:string): Observable<any>{

        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        let params = JSON.stringify(evaluaciondetalle);

        return this._http.post(this.url+'evaluacion-detalle-existe',params, {headers:headers});
    }

    evaluacionDetalleAsignarReconocimiento(evaluaciondetalle: EvaluacionDetalle,token:string): Observable<any>{
        //hay que checar la parte de los tokens
        let params = JSON.stringify(evaluaciondetalle);
        console.log("entre al log de evaluacionDetalleModificar");
        console.log(JSON.stringify(evaluaciondetalle));
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'evaluacion-detalle-asignar-reconocimiento',params, {headers:headers});
    }
}
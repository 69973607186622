//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { Pregunta } from '../../models/pregunta';
import { PreguntaService } from '../../services/pregunta.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Respuesta } from '../../models/respuesta';
import { RespuestaService } from '../../services/respuesta.service';
import { PreguntaRespuesta } from '../../models/preguntarespuesta';
import { PreguntaRespuestaService } from '../../services/preguntarespuesta.service';
import { Rol } from '../../models/rol';
import { Pregreg } from '../../models/pregreg';
import { Area } from '../../models/area';

declare var $:any;

@Component({
    selector:'admin-pregunta-respuesta',
    templateUrl:'./admin-pregunta-respuesta.component.html',
    providers:[UsuarioService,PreguntaService,RespuestaService,PreguntaRespuestaService],
    //styleUrls:['../']
})

export class AdminPreguntaRespuestaComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    public pregunta:Pregunta;
    public status:string;
    public errorTexto:string;
    fromDate;
    public preguntas: Pregunta[];
    public respuestas: Respuesta[];
    public respuestasenpreguntas: Respuesta[];
    public preguntarespuesta:PreguntaRespuesta;
    public rol:Rol;
    public respuesta:Respuesta;
    public pregunta_seleccionada: Pregunta;
    public pregreg:Pregreg;
    public area:Area;
    //public preguntarespuestas: PreguntaRespuesta[];

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;

    @ViewChild(DatatableComponent) table: DatatableComponent; 

    constructor(
        private _usuarioService: UsuarioService,
        private _preguntaService: PreguntaService,
        private _respuestaService: RespuestaService,
        private _preguntarespuestaservice: PreguntaRespuestaService
    ){
        this.title = 'Asignar respuestas a pregunta';
        this.token = this._usuarioService.getToken();
        this.inicializarClase();
        this.GRIDlistar();
}

/**
 * Métodos
 */

 inicializarClase(){
    this.preguntarespuesta = new PreguntaRespuesta(0,0,this.pregunta,0,this.respuesta,new Date(),true,new Date(),new Date(),"");
    this.pregreg = new Pregreg(0,this.pregunta,this.rol,this.area);
    //this.preguntarespuesta2 = new PreguntaRespuesta(0,"",this.pregunta,this.respuesta,new Date(),true,new Date(),new Date(),"",0,0);
    //console.log("LOG DE INICIALIZAR CLASE2: " + JSON.stringify(this.preguntarespuesta2));
    console.log("LOG DE INICIALIZAR CLASE: " + JSON.stringify(this.preguntarespuesta));
    console.log("LOG 2" + JSON.stringify(this.pregreg));
    return this.preguntarespuesta;
 }

ngOnInit(){
    console.log('Componente admin pregunta respuesta cargado');
    this.obtenerRespuestas(this.token);
    this.obtenerPreguntas(this.token);
    this.token = this._usuarioService.getToken();

    this.columns = [
        { name:'ID',prop: 'ID'},
        { name:'DESCRIPCIÓN', prop: 'DESCRIPCION'},
        { name:'ESTATUS', prop:'estatus_texto'}
    ]
}

btn_nuevo(){
    this.inicializarClase();
    this.selectedValuePregunta = this.preguntas[0];
    this.preguntarespuesta.Pregunta = this.selectedValuePregunta;
    console.log("btn_nuevo(): " + JSON.stringify(this.preguntarespuesta));
    this.selectedValueRespuesta = this.respuestas[0];
    this.preguntarespuesta.Respuesta = this.selectedValueRespuesta;
    $('#exampleModal').modal('show');
}

GRIDlistar(){
    this._preguntarespuestaservice.GRIDlistar(this.token).subscribe(
        response =>{
            console.log(JSON.stringify(response.items));
            this.rows = response.items;
            this.temp = response.items;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

obtenerPreguntas(token){
    this._preguntaService.obtenerActivas(token).subscribe(
        response =>{
            this.preguntas = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

obtenerRespuestas(token){
    this._respuestaService.obtenerActivas(token).subscribe(
        response =>{
            this.respuestas = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

selectedValuePregunta: any;
selectedValueRespuesta: any;
doSomethingPregunta() {
    //console.log(this.selectedValue);
    console.log(this.selectedValuePregunta);

    this.preguntarespuesta.Pregunta = this.selectedValuePregunta;
    this.obtenerRespuestasDePregunta(this.preguntarespuesta.Pregunta.ID);
    console.log(JSON.stringify(this.preguntarespuesta));
    //console.log(JSON.stringify("dosomething()" + this.usuario.Rol));
}// setNewUser()

doSomethingRespuesta() { 
    console.log(this.selectedValueRespuesta);
    this.preguntarespuesta.Respuesta = this.selectedValueRespuesta;
    //this.obtenerTerminalesPorSucursal(this.equipo.Sucursal.ID,this.token);
}// setNewUser()

    /*onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }*/
    
    onActivate(event) {
        var a = event;

        if(event.type== 'click'){
            console.log(event);
            this.status = "";
            this.pregunta_seleccionada = event.row;
            //console.log("LOG DE PREGUNTA SELECCIONADA: " + JSON.stringify(this.pregunta_seleccionada.ID));
            this.selectedValuePregunta = this.preguntas.find(x => x.ID === this.pregunta_seleccionada.ID);
            this.selectedValueRespuesta = this.respuestas[0];
            this.preguntarespuesta.Pregunta = this.selectedValuePregunta;
            this.preguntarespuesta.Respuesta = this.selectedValueRespuesta;
            //this.selectedValueRespuesta = this.respuestas.find(x => x.ID === this.preguntarespuesta.ID_RESPUESTA);
            this.obtenerRespuestasDePregunta(this.pregunta_seleccionada.ID);
            $('#exampleModal').modal('show');
        }
        //console.log(JSON.stringify(event));
    /*if(event.type == 'click') {



        //this.selectedValuePregunta = this.preguntas.find(x => x.ID === this.pregunta_seleccionada.ID);
        ////this.selectedValueRespuesta = this.respuestas.find(x => x.ID === this.preguntarespuesta.ID_RESPUESTA);
        //this.obtenerRespuestasDePregunta(this.pregunta_seleccionada.ID);
        $('#exampleModal').modal('show');
    }*/
    //alert("soy un event");
}

eliminar(element){
    var ID_PREGUNTA = this.preguntarespuesta.Pregunta.ID;
    var ID_RESPUESTA = element;
    this._preguntarespuestaservice.eliminar(ID_PREGUNTA,ID_RESPUESTA,this.token).subscribe(
        response =>{
            this.obtenerRespuestasDePregunta(this.pregunta_seleccionada.ID);
            console.log("Respuesta eliminada correctamente");
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

agregar(){
    //console.log(JSON.stringify(this.preguntarespuesta));
    this._preguntarespuestaservice.gestion(this.preguntarespuesta,this.token).subscribe(
        response=>{
            this.obtenerRespuestasDePregunta(this.pregunta_seleccionada.ID);
            console.log("Respuesta agregada correctamente");
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

obtenerRespuestasDePregunta(ID_PREGUNTA){
    this._preguntarespuestaservice.obtenerRespuestasDePregunta(ID_PREGUNTA,this.token).subscribe(
        response=>{
            this.respuestasenpreguntas = response.items;
            console.log("log de preguntarespuestas: " + JSON.stringify(this.respuestasenpreguntas));
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

onSubmit(){
    this._preguntaService.gestion(this.pregunta,this.token).subscribe(
        response =>{
            $('#txtBuscar').val('');
            this.GRIDlistar();
            this.formValues.resetForm(); // Added this
            this.status = "";
            $('#exampleModal').modal('hide');
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();   
    // filter our data
    const temp = this.temp.filter(function(d) {
        return d.DESCRIPCION.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}
}
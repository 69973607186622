import { Encuesta } from './encuesta';
import { Sucursal } from './sucursal';

export class Evaluacion{

    //public ID:number;
    public ID_ENCUESTA:number;
    public Encuesta:Encuesta;
    public FEC_FIN:Date;
    public FOLIO:string;
    public ID_SUCURSAL:number;
    public Sucursal: Sucursal;
    public FEC_REG: Date;
    public ESTATUS:boolean;
    public FECHA_MOD:Date;
    public HHMMSS_MOD:Date; 
    public CVE_USU_MOD:string;

    constructor(
        public ID:number
    ){}

    /*constructor(
        public ID:number,
        public Encuesta:Encuesta,
        public FEC_FIN:Date,
        //public ESTATUS_POR_AREA:number,
        //public ESTATUS_EVALUACION: number,
        public FOLIO:string,
        public Sucursal: Sucursal,
        public FEC_REG: Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string
    ){

    }*/
}
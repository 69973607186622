import { Component, OnInit, ɵConsole } from '@angular/core';
import { UsuarioService} from '../../services/usuario.service';
import { EncuestaAreaService } from '../../services/encuestaarea.service';
import { ClienteService } from '../../services/cliente.service';
import { Cliente } from '../../models/cliente';
import { EncuestaArea } from '../../models/encuestaarea';
import { Pregunta } from '../../models/pregunta';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { Router, ActivatedRoute } from '@angular/router';

import { GLOBAL } from '../../services/global';

import { DatePipe } from '@angular/common';

import { Evaluacion } from '../../models/evaluacion';
import { EvaluacionService } from "../../services/evaluacion.service";
import { Encuesta } from 'src/app/models/encuesta';
import { TipoCliente } from 'src/app/models/tipocliente';
import { Sucursal } from 'src/app/models/sucursal';

import { EquipoFolioService } from '../../services/equipofolio.service';
import { HelperService } from '../../services/helpers.service';
import { FolioAreaService } from '../../services/folioarea.service';
import { strictEqual } from 'assert';


@Component({
    selector:'verificar-datos',
    templateUrl:'verificar-datos.component.html',
    providers:[UsuarioService,EncuestaAreaService, ClienteService,EvaluacionService,EquipoFolioService,HelperService,FolioAreaService]
})

export class VerificarDatosComponent implements OnInit{


    public title:string;
    public identity;
    public encuestaarea:EncuestaArea;
    public status:string;
    public errorTexto:string;
    public preguntas:Pregunta[];
    public folio:string;
    public cliente: Cliente;
    public tipoCliente: TipoCliente;
    public sucursal: Sucursal;

    public folioLocalStorage;

    public url: string;

    public evaluacion: Evaluacion;
    public encuesta: Encuesta;

    public encuestaAreaToken;

    //public estudios=[];
    public ltomaMuestra=[];
    public token;
    public foliourl:string;
    now;

    fromDate;

    constructor(
        private _route:ActivatedRoute,
        private _router:Router,
        private _usuarioService:UsuarioService,
        private _clienteservice:ClienteService,
        private _evaluacionservice:EvaluacionService,
        private _encuestaareaservice:EncuestaAreaService,
        private datePipe: DatePipe,
        private _equipofolioservice:EquipoFolioService,
        private _helperservice:HelperService,
        private _folioareaservice:FolioAreaService
        ){
        this.title = 'Verificar datos';
        this.url = GLOBAL.url;
        
    }

    ngOnInit(){
        console.log("componente verificar datos cargado correctamente");
        this.token = this._usuarioService.getToken();
        this.validarAcceso(this.token);
        //tomamos los datos del folio recibido
        this.foliourl = this._route.snapshot.paramMap.get('FOLIO');

        this.validarFolio(this.foliourl,this.token);
        //this.obtenerClientePorFolio(this.foliourl,this.token);
    }

    validarFolio(folio,token){
        console.log("ENTRE A METODO VALIDAR FOLIO");
        this._equipofolioservice.validarFolio(folio,token).subscribe(
            response=>{
                if(response.item == null){
                    this.redireccionar("404");
                }
                else{
                    this.obtenerClientePorFolio(folio,token);
                    this.asginarColaborador(folio,token);
                }
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    asginarColaborador(folio,token){
        this._folioareaservice.asignarColaborador(folio,token).subscribe(
            response =>{
                console.log("colaborador asignado correctamente");
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    validarAcceso(token){
        this._helperservice.acceso(token).subscribe(
            response=>{
                console.log("componente seleccionar equipo cargado");
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        );
    }

obtenerClientePorFolio(folio,token){
    this._clienteservice.obtenerClientePorFolio(folio,token).subscribe(
        response =>{
            if(Object.keys(response.item).length === 0){
                this.cliente = null;
            }
            else{
                this.cliente = response.item;

                this.status = 'success';
                this.now = this.cliente.FEC_NAC.toLocaleString().replace("T"," ").replace("Z","");;
                //this.fromDate = this.datePipe.transform(this.cliente.FEC_NAC, 'yyyy-MM-dd');
                this.obtenerTomaMuestraPorFolio(this.cliente.FOLIO,this.token);
            }
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

obtenerTomaMuestraPorFolio(folio,token){
    this._helperservice.obtenerTomaMuestraPorFolio(folio,token).subscribe(
    response =>{
        this.ltomaMuestra = response.item;
    },
    err =>{
        var errorMessage = <any>err;
        console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
        this.errorTexto = errorMessage.error.message;
        if(errorMessage !=null){
            this.status = 'error';
        }
    })};

    irEvaluacion(){
        var url = "evaluacion/" + this.cliente.FOLIO;
        this.redireccionar(url); 
    }

    redireccionar(url){
        this._router.navigate([url]);
    }
}
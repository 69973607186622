import { Component, OnInit } from '@angular/core';

@Component({
    selector:'site-footer',
    templateUrl:'./site-footer.component.html'
})

export class SiteFooterComponent implements OnInit{
    constructor(){

    }

    ngOnInit(){
        
    }
}
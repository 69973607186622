import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SiteLayoutComponent } from './components/_layout/site-layout/site-layout.component';

// Componentes
import { EvaluacionComponent } from './components/evaluacion/evaluacion.component';
import { LoginComponent } from './components/login/login.component';
import { AdminEmpresaComponent } from './components/admin-empresa/admin-empresa.component';
import { AdminTerminalComponent } from './components/admin-terminal/admin-terminal.component';
import { AdminEquipoComponent } from './components/admin-equipo/admin-equipo.component';
import { AdminAreaComponent } from './components/admin-area/admin-area.component';
import { AdminPreguntaComponent } from './components/admin-pregunta/admin-pregunta.component';
import { AdminEncuestaComponent } from './components/admin-encuesta/admin-encuesta.component';
import { AdminRespuestaComponent } from './components/admin-respuesta/admin-respuesta.component';
import { AdminReconocimientoComponent } from './components/admin-reconocimiento/admin-reconocimiento.component';
import { AdminPreguntaRespuestaComponent } from './components/admin-pregunta-respuesta/admin-pregunta-respuesta.component';
import { AdminPreguntaReconocimientoComponent } from './components/admin-pregunta-reconocimiento/admin-pregunta-reconocimiento.component';
import { AdminEncuestaAreaComponent } from './components/admin-encuesta-area/admin-encuesta-area.component';
import { AdminEncuestaAreaPreguntaComponent } from './components/admin-encuesta-area-pregunta/admin-encuesta-area-pregunta.component';
//import { EmpresaAgregarComponent } from './components/empresa-agregar/empresa-agregar.component';
import { AdminUsuarioComponent } from './components/admin-usuario/admin-usuario.component';

import { RecepcionComponent } from './components/recepcion/recepcion.component';
import { AuthGuard } from './components/guards/auth.guard';

import { HomeComponent } from './components/home/home.component';
import { TomaMuestraComponent } from './components/toma-muestra/toma-muestra.component';
//import { EntregaResultadosComponent } from './components/entrega-resultados/entrega-resultados.component';
import { VerificarDatosComponent } from './components/verificar-datos/verificar-datos.component';
import { SeleccionarEquipoComponent } from './components/seleccionar-equipo/seleccionar-equipo.component';


//sin auntenticar

import { SiteLayoutTokenComponent } from './components/_layout/site-layout-token/site-layout-token.component';
import { VerificarDatosTokenComponent } from './components/verificar-datos-token/verificar-datos-token.component';
import { EvaluacionTokenComponent } from './components/evaluacion-token/evaluacion-token.component';
//import { TomaMuestraFolioComponent } from './components/toma-muestra-folio/toma-muestra-folio.component';
//import { VerificarDatosFolioComponent } from './components/verificar-datos-folio/verificar-datos-folio.component';

//404

import { NotFoundComponent } from './components/not-found/not-found.component';

//https://stackblitz.com/edit/angular-multi-layout-example?file=app%2Fapp.routing.ts para la solucion al ruteo de padres e hijos
//https://stackoverflow.com/questions/46527826/best-method-to-set-different-layout-for-different-pages-in-angular-4

const appRoutes: Routes = [

    //sitio con header y footer va aquí
    {
        path:'',
        canActivate: [AuthGuard],
        component:SiteLayoutComponent, 
        children:[
            { path:'', component:HomeComponent,pathMatch:'full' },
            { path:'admin-empresa', component:AdminEmpresaComponent },
            { path:'admin-terminal', component:AdminTerminalComponent },
            { path:'admin-equipo', component:AdminEquipoComponent },
            { path:'admin-area', component:AdminAreaComponent },
            { path:'admin-pregunta', component:AdminPreguntaComponent },
            { path:'admin-encuesta', component:AdminEncuestaComponent },
            { path:'admin-respuesta', component:AdminRespuestaComponent },
            { path:'admin-reconocimiento', component:AdminReconocimientoComponent },
            { path:'admin-pregunta-respuesta', component:AdminPreguntaRespuestaComponent },
            { path:'admin-pregunta-reconocimiento', component:AdminPreguntaReconocimientoComponent },
            { path: 'admin-encuesta-area', component: AdminEncuestaAreaComponent },
            { path: 'admin-encuesta-area-pregunta', component: AdminEncuestaAreaPreguntaComponent },
            //{ path:'empresa-agregar', component:EmpresaAgregarComponent },
            //{ path:'ingreso-folio', component:IngresoFolioComponent },
            { path:'recepcion',component:RecepcionComponent },
            { path:'toma-muestra', component:TomaMuestraComponent },
            //{ path:'entrega-resultados', component:EntregaResultadosComponent },
            { path:'verificar-datos/:FOLIO', component:VerificarDatosComponent },
            { path:'evaluacion/:FOLIO', component:EvaluacionComponent },
            { path: 'seleccionar-equipo', component:SeleccionarEquipoComponent },
            { path: 'admin-usuario', component:AdminUsuarioComponent }
        ]
    },
    //sitios que no usan layout
    {path:'login', component:LoginComponent},
    {
        path:'',
        //canActivate:[AuthGuard],
        component:SiteLayoutTokenComponent,
        children:[
            {path:'verificar-datos-token/:token', component:VerificarDatosTokenComponent},
            {path:'evaluacion-token/:TOKEN', component:EvaluacionTokenComponent}
            //{path:'toma-muestra-folio',component:TomaMuestraFolioComponent },
            //{path:'verificar-datos-folio',component:VerificarDatosFolioComponent}
        ]
    },
    //404
    //https://stackoverflow.com/questions/36260839/angular-2-how-to-redirect-to-404-or-other-path-if-the-path-does-not-exist
    {path:'404', component:NotFoundComponent},
    {path:'**', component:NotFoundComponent},
    //,
    //{aux:'login', name:'AuxPath', component:LoginComponent}
    //paginas que no necesitan autenticación
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);

//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { Reconocimiento } from '../../models/reconocimiento';
import { ReconocimientoService } from '../../services/reconocimiento.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { GLOBAL } from '../../services/global';
import { UploadService } from '../../services/upload.service';
 
declare var $:any;

@Component({
    selector:'admin-reconocimiento',
    templateUrl:'./admin-reconocimiento.component.html',
    providers:[UsuarioService,ReconocimientoService,UploadService],
    //styleUrls:['../']
})

export class AdminReconocimientoComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    public reconocimiento:Reconocimiento;
    public status:string;
    public errorTexto:string;
    fromDate;

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    public url: string;

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private _usuarioService: UsuarioService,
        private _reconocimientoService: ReconocimientoService,
        private _uploadService:UploadService
    ){
        this.title = 'Catálogo de reconocimientos';
        this.url = GLOBAL.url;
        this.token = this._usuarioService.getToken();
        this.inicializarClase();
        this.GRIDlistar();
}

/**
 * Métodos
 */

 inicializarClase(){
    this.reconocimiento = new Reconocimiento(0,"","",true,new Date(),true,new Date(),new Date(),"");
    return this.reconocimiento;
 }

ngOnInit(){
    console.log('Componente admin reconocimiento cargado');
    this.token = this._usuarioService.getToken();

    this.columns = [
        { Name:'ID',prop: 'ID'},
        { name:'DESCRIPCIÓN', prop: 'DESCRIPCION'},
        { name:'ES POSITIVO', prop: 'bandera_texto'},
        { name:'ESTATUS', prop:'estatus_texto'}
    ]
}

btn_nuevo(){
    this.inicializarClase();
    $("#fileReconocimiento").val('');
    $('#exampleModal').modal('show');
}

GRIDlistar(){
    this._reconocimientoService.GRIDlistar(this.token).subscribe(
        response =>{
            console.log(JSON.stringify(response.items));
            this.rows = response.items;
            this.temp = response.items;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

    /*onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }*/
    
    onActivate(event) {
    if(event.type == 'click') {
        this.status = "";
        this.reconocimiento = event.row;
        $("#fileReconocimiento").val('');
        $('#exampleModal').modal('show');
    }
}

onSubmit(){
    this._reconocimientoService.gestion(this.reconocimiento,this.token).subscribe(
        response =>{

            var idReconocimiento;
            if(this.reconocimiento.ID ==0){
                idReconocimiento = response.item.ID;
            }
            else{
                idReconocimiento = this.reconocimiento.ID;
            }

            //SUBIDA DE IMAGEN DE EMPRESA
            this._uploadService.makeFileRequest(this.url+'reconocimiento-subir-imagen/'+idReconocimiento,[],this.filesToUpload,this.token,'image')
                .then((result:any)=>{
                    console.log('resultados del makefilerequest' + result);
                    this.reconocimiento.IMAGEN = result.item.IMAGEN;
                    this.GRIDlistar();
                })

            $('#txtBuscar').val('');
            this.formValues.resetForm(); // Added this
            this.status = "";
            $('#exampleModal').modal('hide');
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

public filesToUpload: Array<File>;
fileChangedEvent(fileInput:any){
    this.filesToUpload = <Array<File>>fileInput.target.files;
    console.log('log de filestoupload: ' + this.filesToUpload);
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();   
    // filter our data
    const temp = this.temp.filter(function(d) {
        return d.DESCRIPCION.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}
}
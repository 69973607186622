//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { Encuesta } from '../../models/encuesta';
import { EncuestaService } from '../../services/encuesta.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Area } from '../../models/area';
import { AreaService } from '../../services/area.service';
import { EncuestaAreaPregunta } from '../../models/encuestaareapregunta';
import { EncuestaAreaPreguntaService } from '../../services/encuestaareapregunta.service'
import { EncuestaArea } from '../../models/encuestaarea';
import { EncuestaAreaService } from '../../services/encuestaarea.service';
import { Empresa } from '../../models/empresa';
import { EmpresaService } from '../../services/empresa.service';
import { Pregunta } from '../../models/pregunta';
import { PreguntaService } from '../../services/pregunta.service';

declare var $:any;

@Component({
    selector:'admin-encuesta-area-pregunta',
    templateUrl:'./admin-encuesta-area-pregunta.component.html',
    providers:[UsuarioService,EncuestaService,AreaService,EncuestaAreaService,EmpresaService,EncuestaAreaPreguntaService,PreguntaService],
    //styleUrls:['../']
})

export class AdminEncuestaAreaPreguntaComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    public encuesta:Encuesta;
    public status:string;
    public errorTexto:string;
    fromDate;
    public encuestas: Encuesta[];
    public areas: Area[];
    public areasenencuesta: any[];
    public preguntasenencuesta:any[];
    public encuestaarea:EncuestaArea;
    public encuestaareapregunta:EncuestaAreaPregunta;
    public area:Area;
    public encuesta_seleccionada: any;
    public empresa:Empresa;
    public empresas: Empresa[];
    public preguntas: Pregunta[];
    public pregunta:Pregunta;

    //public preguntarespuestas: PreguntaRespuesta[];

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;

    isEditable = {};

    @ViewChild(DatatableComponent) table: DatatableComponent; 

    constructor(
        private _usuarioService: UsuarioService,
        private _encuestaService: EncuestaService,
        private _areaService: AreaService,
        private _encuestaareaservice: EncuestaAreaService,
        private _empresaservice:EmpresaService,
        private _preguntaservice:PreguntaService,
        private _encuestaareapreguntaservice:EncuestaAreaPreguntaService
    ){
        this.title = 'Asignar preguntas a encuesta';
        this.token = this._usuarioService.getToken();
        this.inicializarClase();
        this.GRIDlistar();
}

/**
 * Métodos
 */

 //
 //https://stackblitz.com/edit/demo-ngxdatatable-enable-editing-for-row-on-button-click?file=src%2Fapp%2Fapp.component.html
 inicializarClase(){
    this.encuestaareapregunta = new EncuestaAreaPregunta(0,this.encuestaarea,this.pregunta,1,new Date(),true,new Date(),new Date(),"",false);
    this.encuestaarea = new EncuestaArea(0,0,this.area,0,this.encuesta,0,this.empresa,1,new Date(),true,new Date(),new Date(),"");
    return this.encuestaareapregunta;
 }

ngOnInit(){
    console.log('Componente admin encuesta area pregunta cargado');

    this.token = this._usuarioService.getToken();
        //this.obtenerAreas(this.token);

        this.obtenerEmpresas(this.token);
        //this.obtenerPreguntas(this.token);

    this.columns = [
        { name:'ID',prop: 'ID_ENCUESTA'},
        { name:'DESCRIPCIÓN', prop: 'DESCRIPCION'},
        { name:'EMPRESA', prop: 'EMPRESA'},
        { name:'ESTATUS', prop:'ESTATUS'}
    ]
}

btn_nuevo(){
    this.inicializarClase();
    this.selectedValueEmpresa = this.empresas[0];
    console.log("nuevo:" + JSON.stringify(this.selectedValueEmpresa));
    this.encuestaareapregunta.EncuestaArea.Empresa = this.selectedValueEmpresa;
    //this.encuestaareapregunta.EncuestaArea = this.encuestaarea;
    /*this.selectedValueEncuesta = this.encuestas[0];
    this.encuestaarea.Encuesta = this.selectedValueEncuesta;
    this.encuestaareapregunta.EncuestaArea = this.encuestaarea;*/
    this.doSomethingEmpresa('nuevo');
    //console.log("btn_nuevo(): " + JSON.stringify(this.preguntareconocimiento));
    /*this.selectedValueArea = this.areas[0];
    this.encuestaareapregunta.EncuestaArea.Area = this.selectedValueArea;*/

    //this.selectedValueEmpresa = this.empresas[0];
    //this.encuestaareapregunta.EncuestaArea.Empresa = this.selectedValueEmpresa;
    $('#exampleModal').modal('show');
}

GRIDlistar(){
    this._encuestaareapreguntaservice.GRIDlistar(this.token).subscribe(
        response =>{
            console.log(JSON.stringify(response.items));
            this.rows = response.items;
            this.temp = response.items;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

obtenerEncuestas(ID_EMPRESA,token,valor){
    this._encuestaareaservice.obtenerEncuestasActivasDeEncuestaArea(ID_EMPRESA,token).subscribe(
        response =>{
            this.encuestas = response.items;
            if(valor==='nuevo' || valor ==='seleccion'){
                this.selectedValueEncuesta = this.encuestas[0];
            }else{
                this.selectedValueEncuesta = this.encuestas.find(x=>x.ID===this.encuesta_seleccionada.ID_ENCUESTA);//this.encuestaareapregunta.EncuestaArea.Encuesta.ID);
            }

            this.encuestaareapregunta.EncuestaArea.Encuesta = this.selectedValueEncuesta;

            this.obtenerAreas(this.selectedValueEncuesta.ID,ID_EMPRESA,token,'nuevo');

            console.log("log de obtener encuestas: " + JSON.stringify(this.encuestas));
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

obtenerAreas(ID_ENCUESTA,ID_EMPRESA,token, valor){
    this._encuestaareaservice.obtenerAreasActivasDeEncuestaArea(ID_ENCUESTA,ID_EMPRESA,token).subscribe(
        response =>{
            this.areas = response.items;
            if(valor==='nuevo' || valor ==='seleccion'){
                this.selectedValueArea = this.areas[0];
            }else{
                this.selectedValueArea = this.areas[0];
                //this.selectedValueArea = this.areas.find(x=>x.ID===this.encuesta_seleccionada.ID_ENCUESTA);//this.encuestaareapregunta.EncuestaArea.Encuesta.ID);
            }

            this.encuestaareapregunta.EncuestaArea.Area = this.selectedValueArea;

            this.obtenerPreguntas(token);
            this.obtenerPreguntasDeEncuestaArea(this.selectedValueEncuesta.ID,this.selectedValueEmpresa.ID,this.selectedValueArea.ID);
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

obtenerEmpresas(token){
    this._encuestaareaservice.obtenerEmpresasActivasDeEncuestaArea(token).subscribe(
        response =>{
            this.empresas = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

obtenerPreguntas(token){
    this._preguntaservice.obtenerActivas(token).subscribe(
        response =>{
            this.preguntas = response.items;
            this.selectedValuePregunta = this.preguntas[0];
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

selectedValueEncuesta: any;
selectedValueArea: any;
selectedValueEmpresa: any;
selectedValuePregunta:any;

doSomethingEncuesta() {
    //console.log(this.selectedValue);
    //console.log(this.selectedValueEncuesta);

    this.encuestaareapregunta.EncuestaArea.Encuesta = this.selectedValueEncuesta;
    //this.obtenerAreaDeEncuesta(this.encuestaarea.Encuesta.ID,this.encuestaarea.Empresa.ID);
    //console.log(JSON.stringify(this.encuestaarea));
    //console.log(JSON.stringify("dosomething()" + this.usuario.Rol));
}// setNewUser()

doSomethingArea() { 
    console.log(this.selectedValueArea);
    this.encuestaareapregunta.EncuestaArea.Area = this.selectedValueArea;
    this.obtenerPreguntasDeEncuestaArea(this.selectedValueEncuesta.ID,this.selectedValueEmpresa.ID,this.selectedValueArea.ID);
    //this.obtenerTerminalesPorSucursal(this.equipo.Sucursal.ID,this.token);
}// setNewUser()

    /*onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }*/

doSomethingEmpresa(valor){
    console.log(JSON.stringify("hola"));
    this.encuestaareapregunta.EncuestaArea.Empresa = this.selectedValueEmpresa;
    
    this.obtenerEncuestas(this.encuestaareapregunta.EncuestaArea.Empresa.ID,this.token,valor);
    //this.obtenerAreaDeEncuesta(this.encuestaarea.Encuesta.ID,this.encuestaarea.Empresa.ID);
}

doSomethingPregunta(){
    this.encuestaareapregunta.Pregunta = this.selectedValuePregunta;
    //this.obtenerAreaDeEncuesta(this.encuestaarea.Encuesta.ID,this.encuestaarea.Empresa.ID);
}
    
    onActivate(event) {
        if(event.type== 'click'){
            this.inicializarClase();
            this.status = "";
            this.encuesta_seleccionada = event.row;
            //console.log("LOG DE ENCUESTA_AREA SELECCIONADA: " + JSON.stringify(this.encuesta_seleccionada));
            //this.selectedValueEncuesta = this.encuestas.find(x => x.ID === this.encuesta_seleccionada.ID_ENCUESTA);
            //this.selectedValueArea = this.areas[0];
            /*this.selectedValueEmpresa = this.empresas.find(x => x.ID === this.encuesta_seleccionada.ID_EMPRESA);
            this.encuestaareapregunta.EncuestaArea.Empresa = this.selectedValueEncuesta;*/

            this.selectedValueEmpresa = this.empresas.find(x => x.ID === this.encuesta_seleccionada.ID_EMPRESA);
            //console.log(JSON.stringify("registro seleccionao: " + JSON.stringify(this.selectedValueEmpresa)));
            //console.log("empresa seleccionada: " + JSON.stringify(this.selectedValueEmpresa));
            //this.encuestaareapregunta.EncuestaArea.Empresa = this.selectedValueEmpresa;
            //this.encuestaarea.Area = this.selectedValueArea;
            //this.encuestaarea.Empresa = this.selectedValueEmpresa;
            this.doSomethingEmpresa('modificar');
            //this.obtenerAreaDeEncuesta(this.encuesta_seleccionada.ID_ENCUESTA,this.encuesta_seleccionada.ID_EMPRESA);
            $('#exampleModal').modal('show');
        }
        //console.log(JSON.stringify(event));
    /*if(event.type == 'click') {



        //this.selectedValuePregunta = this.preguntas.find(x => x.ID === this.pregunta_seleccionada.ID);
        ////this.selectedValueRespuesta = this.respuestas.find(x => x.ID === this.preguntarespuesta.ID_RESPUESTA);
        //this.obtenerRespuestasDePregunta(this.pregunta_seleccionada.ID);
        $('#exampleModal').modal('show');
    }*/
    //alert("soy un event");
}

eliminar(element){
    //var ID_ENCUESTA = this.encuestaarea.Encuesta.ID;
    var ID_ENCUESTA_AREA = element;
    this._encuestaareapreguntaservice.eliminar(ID_ENCUESTA_AREA,this.token).subscribe(
        response =>{
            this.obtenerPreguntasDeEncuestaArea(this.selectedValueEncuesta.ID,this.selectedValueEmpresa.ID,this.selectedValueArea.ID);
            console.log("Respuesta eliminada correctamente");
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

agregar(){
    console.log("valor de encuestaareaPregunta: " + JSON.stringify(this.encuestaareapregunta));
    //console.log(JSON.stringify(this.preguntarespuesta));
    //console.log("LOG DE AGREGAR: " + JSON.stringify(this.encuestaarea));
    this._encuestaareapreguntaservice.gestion(this.encuestaareapregunta,this.token).subscribe(
        response=>{
            this.obtenerPreguntasDeEncuestaArea(this.selectedValueEncuesta.ID,this.selectedValueEmpresa.ID,this.selectedValueArea.ID);
            console.log("Respuesta agregada correctamente");
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

obtenerPreguntasDeEncuestaArea(ID_ENCUESTA,ID_EMPRESA,ID_AREA){
    this._encuestaareapreguntaservice.obtenerPreguntasDeEncuestaArea(ID_ENCUESTA,ID_EMPRESA,ID_AREA,this.token).subscribe(
        response=>{
            this.areasenencuesta = response.items;
            console.log("log de PREGUNTAS EN ENCUESTA: " + JSON.stringify(this.areasenencuesta));
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

onSubmit(){
    this._encuestaService.gestion(this.encuesta,this.token).subscribe(
        response =>{
            $('#txtBuscar').val('');
            this.GRIDlistar();
            this.formValues.resetForm(); // Added this
            this.status = "";
            $('#exampleModal').modal('hide');
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();   
    // filter our data
    const temp = this.temp.filter(function(d) {
        return d.DESCRIPCION.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}
}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { GLOBAL } from './global'; // la url del API
import { PreguntaRespuesta } from '../models/preguntarespuesta';

@Injectable()
export class PreguntaRespuestaService{

    public url:string; //url de nuestro backend

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    /* CATALOGO */

    GRIDlistar(token:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'GRID-listar-preguntarespuestas', {headers:headers});
    }

    /* FUNCIONES */

    /**************************************/

    /*gestion(item: Pregunta,token:string): Observable<any>{
        let params = JSON.stringify(item);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        if(item.ID==0){
            return this._http.post(this.url+'pregunta-agregar',params, {headers:headers});
        }
        else{
            return this._http.put(this.url+'pregunta-modificar',params,{headers:headers});
        }
    }*/

    obtenerRespuestasDePregunta(ID_PREGUNTA:number,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});
        console.log("valor de id_pregunta: " + ID_PREGUNTA);

        return this._http.get(this.url+'obtener-respuestas-de-pregunta/'+ID_PREGUNTA,{headers:headers});
    }

    gestion(item: PreguntaRespuesta,token:string): Observable<any>{
        let params = JSON.stringify(item);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

            return this._http.post(this.url+'preguntarespuesta-agregar',params, {headers:headers});
    }

    eliminar(ID_PREGUNTA:number,ID_RESPUESTA,token:string): Observable<any>{
        let params = JSON.stringify({ID_PREGUNTA:ID_PREGUNTA,ID_RESPUESTA:ID_RESPUESTA});
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

            return this._http.put(this.url+'preguntarespuesta-eliminar',params, {headers:headers});
    }
}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { GLOBAL } from './global'; // la url del API
import { Equipo } from '../models/equipo';

@Injectable()
export class EquipoService{

    public url:string; //url de nuestro backend

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    /* CATALOGO */

    GRIDlistar(token:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'GRID-listar-equipos', {headers:headers});
    }

    /* FUNCIONES */

    gestion(item: Equipo,token:string): Observable<any>{
        let params = JSON.stringify(item);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        if(item.ID==0){
            return this._http.post(this.url+'equipo-agregar',params, {headers:headers});
        }
        else{
            return this._http.put(this.url+'equipo-modificar',params,{headers:headers});
        }
    }

    buscarPorId(id:number,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'equipo-obtener-id/'+id,{headers:headers});
    }
}
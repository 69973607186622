export class Respuesta{
    constructor(
        public ID:number,
        public DESCRIPCION:string,
        public VALOR:number,
        public IMAGEN:string,
        public BANDERA: boolean,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string
    ){

    }
}

import { Pregunta } from './pregunta';
import { Rol } from './rol'
import { Area } from './area';
export class Pregreg{
    constructor(
        public ID_PREGUNTA:number,
        public Pregunta:Pregunta,
        public Rol:Rol,
        public Area:Area
    )
    {

    }
}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class TipoService{

    public url:string; //url de nuestro backend
    public encuestaArea:string;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    obtenerUrl(token:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'obtener-url',null, {headers:headers});
    }

    obtenerActivas(token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});

        return this._http.get(this.url+'tipos-obtener-activas',{headers:headers});
    }
}
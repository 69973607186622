import { Sucursal } from './sucursal';

export class Terminal{

    public ID:number;
    public DESCRIPCION: string;
    public ID_SUCURSAL:number;
    public Sucursal:Sucursal;
    public FEC_REG:Date;
    public ESTATUS:boolean;
    public FECHA_MOD:Date;
    public HHMMSS_MOD:Date;
    public CVE_USU_MOD:string;
    public NUM_SERIE:string;

    /*constructor(
        public ID:number,
        public DESCRIPCION: string,
        public Sucursal:Sucursal,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string
    ){

    }*/

    constructor(
        //public ID:number
    ){
        this.Sucursal = new Sucursal();
    }
}

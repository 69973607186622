import { Component, OnInit } from '@angular/core';
import { UsuarioService} from '../../services/usuario.service';
import { EncuestaAreaService } from '../../services/encuestaarea.service';
import { ClienteService } from '../../services/cliente.service';
import { Cliente } from '../../models/cliente';
import { Router, ActivatedRoute } from '@angular/router';
import { GLOBAL } from '../../services/global';
import { DatePipe } from '@angular/common';
import { HelperService } from '../../services/helpers.service';
import { FolioDetalleService } from '../../services/foliodetalle.service';

declare var $:any;

@Component({
    selector:'recepcion',
    templateUrl:'./recepcion.component.html',
    providers:[UsuarioService,EncuestaAreaService, ClienteService,HelperService,FolioDetalleService]
})

export class RecepcionComponent implements OnInit{
    public title:string;
    public identity;
    public status:string;
    public errorTexto:string;
    public cliente: Cliente;
    public url: string;
     fromDate;
     now;

    public estudios=[];

    public token;

    //realtime
    	/**
	* Interval to update the chart
	* @var {any} intervalUpdate
	*/
    private intervalUpdate: any = null;

    constructor(
        private _route:ActivatedRoute,
        private _router:Router,
        private _usuarioservice:UsuarioService,
        private _clienteservice:ClienteService,
        private datePipe: DatePipe,
        private _helperservice:HelperService,
        private _foliodetalleservice:FolioDetalleService
    ){
        this.title = 'Bienvenido a Datos cliente';
        this.url = GLOBAL.url;
    }

            /*console.log("entre a on init")
        this._route.queryParams.subscribe(params=>{
            //this.cliente2.FOLIO = params['folio'];
            this.folio = params['folio'];
            //alert(this.folio);
        });*/

    ngOnInit(){ 
        console.log('Componente datos cliente cargado');
        this.token = this._usuarioservice.getToken();
        this.validarAcceso(this.token);
        this.showData();
		this.intervalUpdate = setInterval(function(){
			this.showData();
        }.bind(this), 7000); //7 segs
    }

    validarAcceso(token){
        this._helperservice.acceso(token).subscribe(
            response=>{
                console.log("componente seleccionar equipo cargado");
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        );
    }

    private ngOnDestroy(): void {
		clearInterval(this.intervalUpdate);
}

    private showData(): void {
        this.obtenerClientePorFolio(this.token);
    }

    obtenerClientePorFolio(token){
        this._clienteservice.clientePorFolio(token).subscribe(
            response =>{
                if(response.item==null){
                    this.cliente = null;
                    //console.log(JSON.stringify(response.item));
                }
                else{
                    this.cliente = response.item;
                    if(this.cliente.ESTATUS_ORDEN=='P')
                    
                    {
                        this.irEvaluacion();
                    }
                    else{
                        console.log(JSON.stringify(this.cliente));
                        this.status = 'success';
                        this.fromDate = this.datePipe.transform(this.cliente.FEC_NAC, 'yyyy-MM-dd');
                        console.log("FEC NAC "+ this.cliente.FEC_NAC);
                        console.log("FROM DATE "+ this.fromDate);
                        this.now = this.cliente.FEC_NAC.toLocaleString().replace("T"," ").replace("Z","");;
                        console.log("valor de now: " +this.now);
                        this.obtenerEstudiosPorIdCliente(this.cliente.ID,this.token);
                        //this.cliente = null;
                        //console.log(JSON.stringify(response.item));
                    }
                } 
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    /*obtenerEstudiosPorFolio(folio,token){
        this._helperservice.obtenerEstudiosPorFolio(folio,token).subscribe(
        response =>{
            this.estudios = response.item;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        })
    };*/

    obtenerEstudiosPorIdCliente(ID_FOLIO_CLIENTE,token){
        this._foliodetalleservice.obtenerEstudiosPorIdCliente(ID_FOLIO_CLIENTE,token).subscribe(
        response =>{
            this.estudios = response.item;
            console.log(JSON.stringify(this.estudios));
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        })
    };

    irEvaluacion(){
        var url = "evaluacion/" + this.cliente.FOLIO;
        this.redireccionar(url); 
    }

    redireccionar(url){
        this._router.navigate([url]);
    }
}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { Menu } from '../models/menu'; //
import { GLOBAL } from './global'; // la url del API

import { Usuario } from '../models/usuario';

@Injectable()
export class MenuService{
    public url:string; //url de nuestro backend
    public identity;
    public token;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    obtenerMenu(token:string): Observable<any>{
        console.log("entre a metodo de obtener menu service");
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'obtener-menu', {headers:headers});
    }
}
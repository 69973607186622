export class Empresa{
    constructor(
        public ID:number,
        public DESCRIPCION:string,
        public DESCRIPCION2:string,
        public IMAGEN:string,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string
    ){

    }
}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { EncuestaArea } from '../models/encuestaarea'; //
import { GLOBAL } from './global'; // la url del API
import { EquipoSeleccionado } from '../models/equiposeleccionado';

@Injectable()
export class EquipoSeleccionadoService{

    public url:string; //url de nuestro backend
    public encuestaArea:string;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    agregar(equipo:EquipoSeleccionado,token:string): Observable<any>{

        let params = JSON.stringify(equipo);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'equipo-seleccionado-agregar',params, {headers:headers});
    }
}
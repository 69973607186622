import { Encuesta } from './encuesta';
import { Sucursal } from './sucursal';
import { Evaluacion } from './evaluacion';
import { Pregunta } from './pregunta';
import { Respuesta } from './respuesta';
import { Reconocimiento } from './reconocimiento';
import { EncuestaArea } from './encuestaarea';

export class EvaluacionDetalle{

    public ID:number;
    public ID_EVALUACION:number;
    public Evaluacion:Evaluacion;
    public ID_PREGUNTA:number;
    public Pregunta:Pregunta;
    public ID_RESPUESTA:number;
    public Respuesta:Respuesta;
    public ID_RECONOCIMIENTO:number;
    public Reconocimiento: Reconocimiento;
    public ID_ENCUESTAAREA:number;
    public EncuestaArea:EncuestaArea;
    public FEC_PREGUNTA: Date;
    public FEC_RECONOCIMIENTO: Date;
    public FEC_REG: Date;
    public CVE_COL:string;
    public ESTATUS:boolean;
    public FECHA_MOD:Date;
    public HHMMSS_MOD:Date;
    public CVE_USU_MOD:string;

    /*constructor(
        public ID:number,
        public Evaluacion:Evaluacion,
        public Pregunta:Pregunta,
        public Respuesta:Respuesta,
        public Reconocimiento: Reconocimiento,
        public EncuestaArea:EncuestaArea,
        public FEC_PREGUNTA: Date,
        public FEC_RECONOCIMIENTO: Date,
        public FEC_REG: Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string
    ){

    }*/
}
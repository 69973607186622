import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing, appRoutingProviders } from './app.routing'; // este se agrego
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // se importa este sino sale error cuando quiere trabajar ya con los formularios. 
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; // para hacer peticiones http y ajax es el mas nuevo
//para que no marque error al refrescar las url. ponerle un # en las urls se agrega LocationStrategy, HashLocationStrategy
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common'; // este se agrego para parsear la fecha alc ontrol date

import { AuthGuard } from './components/guards/auth.guard';

//import { AppRoutingModule } from './app-routing.module';
//import { CommonModule } from '@angular/common';

// Componentes
import { SiteLayoutComponent } from './components/_layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './components/_layout/site-header/site-header.component';
import { SiteFooterComponent } from './components/_layout/site-footer/site-footer.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component'; // este se agrego
import { EvaluacionComponent } from './components/evaluacion/evaluacion.component';
import { AdminEmpresaComponent } from './components/admin-empresa/admin-empresa.component';
import { AdminTerminalComponent } from './components/admin-terminal/admin-terminal.component';
import { AdminEquipoComponent } from './components/admin-equipo/admin-equipo.component';
import { AdminAreaComponent } from './components/admin-area/admin-area.component';
import { AdminPreguntaComponent } from './components/admin-pregunta/admin-pregunta.component';
import { AdminEncuestaComponent } from './components/admin-encuesta/admin-encuesta.component';
import { AdminRespuestaComponent } from './components/admin-respuesta/admin-respuesta.component';
import { AdminReconocimientoComponent } from './components/admin-reconocimiento/admin-reconocimiento.component';
import { AdminPreguntaRespuestaComponent } from './components/admin-pregunta-respuesta/admin-pregunta-respuesta.component';
import { AdminPreguntaReconocimientoComponent } from './components/admin-pregunta-reconocimiento/admin-pregunta-reconocimiento.component';
import { AdminEncuestaAreaComponent } from './components/admin-encuesta-area/admin-encuesta-area.component';
import { AdminEncuestaAreaPreguntaComponent } from './components/admin-encuesta-area-pregunta/admin-encuesta-area-pregunta.component';
//import { EmpresaAgregarComponent } from './components/empresa-agregar/empresa-agregar.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RecepcionComponent } from './components/recepcion/recepcion.component';
import { UsuarioService } from './services/usuario.service';
import { AdminUsuarioComponent } from './components/admin-usuario/admin-usuario.component';

import { HomeComponent } from './components/home/home.component';
import { TomaMuestraComponent } from './components/toma-muestra/toma-muestra.component';
//import { EntregaResultadosComponent } from './components/entrega-resultados/entrega-resultados.component';

import { VerificarDatosComponent } from './components/verificar-datos/verificar-datos.component';

import { SeleccionarEquipoComponent } from './components/seleccionar-equipo/seleccionar-equipo.component';

//import { MenuComponent } from './components/'

//sin autenticar
import { VerificarDatosTokenComponent } from './components/verificar-datos-token/verificar-datos-token.component';
import { SiteLayoutTokenComponent } from './components/_layout/site-layout-token/site-layout-token.component';
import { EvaluacionTokenComponent } from './components/evaluacion-token/evaluacion-token.component';
//import { TomaMuestraFolioComponent } from './components/toma-muestra-folio/toma-muestra-folio.component';
//import { VerificarDatosFolioComponent } from './components/verificar-datos-folio/verificar-datos-folio.component';

// 404
import { NotFoundComponent } from './components/not-found/not-found.component';

//Interceptor
import { MyHttpInterceptor } from './services/MyHttpInterceptor';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EvaluacionComponent,
    AdminEmpresaComponent,
    AdminTerminalComponent,
    AdminEquipoComponent,
    AdminAreaComponent,
    AdminPreguntaComponent,
    AdminEncuestaComponent,
    AdminRespuestaComponent,
    AdminReconocimientoComponent,
    AdminPreguntaRespuestaComponent,
    AdminPreguntaReconocimientoComponent,
    AdminUsuarioComponent,
    AdminEncuestaAreaComponent,
    AdminEncuestaAreaPreguntaComponent,
    //EmpresaAgregarComponent,
    SiteFooterComponent,
    SiteHeaderComponent,
    SiteLayoutComponent,
    //IngresoFolioComponent,
    RecepcionComponent,
    HomeComponent,
    TomaMuestraComponent,
    //EntregaResultadosComponent, 
    VerificarDatosComponent,
    SeleccionarEquipoComponent,
    VerificarDatosTokenComponent,
    SiteLayoutTokenComponent,
    EvaluacionTokenComponent,
    //TomaMuestraFolioComponent,
    //VerificarDatosFolioComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    routing, // setes re agrego
    FormsModule, // este se agrego
    HttpClientModule
    ,
    NgxDatatableModule
    //,
    //CommonModule
  ],
  providers: [
    appRoutingProviders, //este se agrego
    DatePipe,
    AuthGuard,
    UsuarioService,
    {
      provide:HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi:true
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { GLOBAL } from './global'; // la url del API
import { Area } from '../models/area';

@Injectable()
export class AreaService{

    public url:string; //url de nuestro backend

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    /* CATALOGO */

    GRIDlistarAreas(token:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'GRID-listar-areas', {headers:headers});
    }

    /* FUNCIONES */

    /**************************************/

    gestion(item: Area,token:string): Observable<any>{
        let params = JSON.stringify(item);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        if(item.ID==0){
            return this._http.post(this.url+'area-agregar',params, {headers:headers});
        }
        else{
            return this._http.put(this.url+'area-modificar',params,{headers:headers});
        }
    }
    obtenerActivas(token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});

        return this._http.get(this.url+'areas-obtener-activas',{headers:headers});
    }
}
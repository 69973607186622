//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { Pregunta } from '../../models/pregunta';
import { PreguntaService } from '../../services/pregunta.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Reconocimiento } from '../../models/reconocimiento';
import { ReconocimientoService } from '../../services/reconocimiento.service';
import { PreguntaReconocimiento } from '../../models/preguntareconocimiento';
import { PreguntaReconocimientoService } from '../../services/preguntareconocimiento.service';
import { Rol } from '../../models/rol';
import { Pregreg } from '../../models/pregreg';
import { Area } from '../../models/area';

declare var $:any;

@Component({
    selector:'admin-pregunta-reconocimiento',
    templateUrl:'./admin-pregunta-reconocimiento.component.html',
    providers:[UsuarioService,PreguntaService,ReconocimientoService,PreguntaReconocimientoService],
    //styleUrls:['../']
})

export class AdminPreguntaReconocimientoComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    public pregunta:Pregunta;
    public status:string;
    public errorTexto:string;
    fromDate;
    public preguntas: Pregunta[];
    public reconocimientos: Reconocimiento[];
    public reconocimientosenpreguntas: Reconocimiento[];
    public preguntareconocimiento:PreguntaReconocimiento;
    public rol:Rol;
    public reconocimiento:Reconocimiento;
    public pregunta_seleccionada: Pregunta;
    public pregreg:Pregreg;
    public area:Area;
    //public preguntarespuestas: PreguntaRespuesta[];

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;



    isEditable = {};


    @ViewChild(DatatableComponent) table: DatatableComponent; 

    constructor(
        private _usuarioService: UsuarioService,
        private _preguntaService: PreguntaService,
        private _reconocimientoService: ReconocimientoService,
        private _preguntareconocimientoservice: PreguntaReconocimientoService
    ){
        this.title = 'Asignar reconocimientos a pregunta';
        this.token = this._usuarioService.getToken();
        this.inicializarClase();
        this.GRIDlistar();
}

/**
 * Métodos
 */

 //
 //https://stackblitz.com/edit/demo-ngxdatatable-enable-editing-for-row-on-button-click?file=src%2Fapp%2Fapp.component.html
 inicializarClase(){
    this.preguntareconocimiento = new PreguntaReconocimiento(0,0,this.pregunta,0,this.reconocimiento,new Date(),true,new Date(),new Date(),"");
    return this.preguntareconocimiento;
 }

ngOnInit(){
    console.log('Componente admin pregunta reconocimiento cargado');
    this.obtenerReconocimientos(this.token);
    this.obtenerPreguntas(this.token);
    this.token = this._usuarioService.getToken();

    this.columns = [
        { name:'ID',prop: 'ID'},
        { name:'DESCRIPCIÓN', prop: 'DESCRIPCION'},
        { name:'ESTATUS', prop:'estatus_texto'}
    ]
}

  // Open/close panel  
  toggleExpandRow(row, expanded) {
    this.table.rowDetail.toggleExpandRow(row);
    if(!expanded){
      this.table.rowDetail.collapseAllRows();
      this.table.rowDetail.toggleExpandRow(row);
    }
    else if (expanded){
      this.table.rowDetail.collapseAllRows();
    }
  }

  // Save row
  save(row, rowIndex){
    this.isEditable[rowIndex]=!this.isEditable[rowIndex]
    console.log("Row saved: "+ rowIndex);
  }

  // Delete row
  delete(row, rowIndex){
    this.isEditable[rowIndex]=!this.isEditable[rowIndex]
    console.log("Row deleted: "+ rowIndex);
  }

btn_nuevo(){
    this.inicializarClase();
    this.selectedValuePregunta = this.preguntas[0];
    this.preguntareconocimiento.Pregunta = this.selectedValuePregunta;
    console.log("btn_nuevo(): " + JSON.stringify(this.preguntareconocimiento));
    this.selectedValueReconocimiento= this.reconocimientos[0];
    this.preguntareconocimiento.Reconocimiento = this.selectedValueReconocimiento;
    $('#exampleModal').modal('show');
}

GRIDlistar(){
    this._preguntareconocimientoservice.GRIDlistar(this.token).subscribe(
        response =>{
            console.log(JSON.stringify(response.items));
            this.rows = response.items;
            this.temp = response.items;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

obtenerPreguntas(token){
    this._preguntaService.obtenerActivas(token).subscribe(
        response =>{
            this.preguntas = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

obtenerReconocimientos(token){
    this._reconocimientoService.obtenerActivas(token).subscribe(
        response =>{
            this.reconocimientos = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

selectedValuePregunta: any;
selectedValueReconocimiento: any;
doSomethingPregunta() {
    //console.log(this.selectedValue);
    console.log(this.selectedValuePregunta);

    this.preguntareconocimiento.Pregunta = this.selectedValuePregunta;
    this.obtenerReconocimientoDePregunta(this.preguntareconocimiento.Pregunta.ID);
    console.log(JSON.stringify(this.preguntareconocimiento));
    //console.log(JSON.stringify("dosomething()" + this.usuario.Rol));
}// setNewUser()

doSomethingReconocimiento() { 
    console.log(this.selectedValueReconocimiento);
    this.preguntareconocimiento.Reconocimiento = this.selectedValueReconocimiento;
    //this.obtenerTerminalesPorSucursal(this.equipo.Sucursal.ID,this.token);
}// setNewUser()

    /*onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }*/
    
    onActivate(event) {
        var a = event;

        if(event.type== 'click'){
            console.log(event);
            this.status = "";
            this.pregunta_seleccionada = event.row;
            //console.log("LOG DE PREGUNTA SELECCIONADA: " + JSON.stringify(this.pregunta_seleccionada.ID));
            this.selectedValuePregunta = this.preguntas.find(x => x.ID === this.pregunta_seleccionada.ID);
            this.selectedValueReconocimiento = this.reconocimientos[0];
            this.preguntareconocimiento.Pregunta = this.selectedValuePregunta;
            this.preguntareconocimiento.Reconocimiento = this.selectedValueReconocimiento;
            //this.selectedValueRespuesta = this.respuestas.find(x => x.ID === this.preguntarespuesta.ID_RESPUESTA);
            this.obtenerReconocimientoDePregunta(this.pregunta_seleccionada.ID);
            $('#exampleModal').modal('show');
        }
        //console.log(JSON.stringify(event));
    /*if(event.type == 'click') {



        //this.selectedValuePregunta = this.preguntas.find(x => x.ID === this.pregunta_seleccionada.ID);
        ////this.selectedValueRespuesta = this.respuestas.find(x => x.ID === this.preguntarespuesta.ID_RESPUESTA);
        //this.obtenerRespuestasDePregunta(this.pregunta_seleccionada.ID);
        $('#exampleModal').modal('show');
    }*/
    //alert("soy un event");
}

eliminar(element){
    var ID_PREGUNTA = this.preguntareconocimiento.Pregunta.ID;
    var ID_RECONOCIMIENTO = element;
    this._preguntareconocimientoservice.eliminar(ID_PREGUNTA,ID_RECONOCIMIENTO,this.token).subscribe(
        response =>{
            this.obtenerReconocimientoDePregunta(this.pregunta_seleccionada.ID);
            console.log("Respuesta eliminada correctamente");
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

agregar(){
    //console.log(JSON.stringify(this.preguntarespuesta));
    this._preguntareconocimientoservice.gestion(this.preguntareconocimiento,this.token).subscribe(
        response=>{
            this.obtenerReconocimientoDePregunta(this.pregunta_seleccionada.ID);
            console.log("Respuesta agregada correctamente");
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

obtenerReconocimientoDePregunta(ID_PREGUNTA){
    this._preguntareconocimientoservice.obtenerReconocimientosDePregunta(ID_PREGUNTA,this.token).subscribe(
        response=>{
            this.reconocimientosenpreguntas = response.items;
            console.log("log de preguntareconocimientos: " + JSON.stringify(this.reconocimientosenpreguntas));
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

onSubmit(){
    this._preguntaService.gestion(this.pregunta,this.token).subscribe(
        response =>{
            $('#txtBuscar').val('');
            this.GRIDlistar();
            this.formValues.resetForm(); // Added this
            this.status = "";
            $('#exampleModal').modal('hide');
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();   
    // filter our data
    const temp = this.temp.filter(function(d) {
        return d.DESCRIPCION.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}
}
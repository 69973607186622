//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { Encuesta } from '../../models/encuesta';
import { EncuestaService } from '../../services/encuesta.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Area } from '../../models/area';
import { AreaService } from '../../services/area.service';
import { EncuestaArea } from '../../models/encuestaarea';
import { EncuestaAreaService } from '../../services/encuestaarea.service';
import { Empresa } from '../../models/empresa';
import { EmpresaService } from '../../services/empresa.service';

declare var $:any;

@Component({
    selector:'admin-encuesta-area',
    templateUrl:'./admin-encuesta-area.component.html',
    providers:[UsuarioService,EncuestaService,AreaService,EncuestaAreaService,EmpresaService],
    //styleUrls:['../']
})

export class AdminEncuestaAreaComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    public encuesta:Encuesta;
    public status:string;
    public errorTexto:string;
    fromDate;
    public encuestas: Encuesta[];
    public areas: Area[];
    public areasenencuesta: any[];
    public encuestaarea:EncuestaArea;
    public area:Area;
    public encuesta_seleccionada: any;
    public empresa:Empresa;
    public empresas: Empresa[];

    //public preguntarespuestas: PreguntaRespuesta[];

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;



    isEditable = {};


    @ViewChild(DatatableComponent) table: DatatableComponent; 

    constructor(
        private _usuarioService: UsuarioService,
        private _encuestaService: EncuestaService,
        private _areaService: AreaService,
        private _encuestaareaservice: EncuestaAreaService,
        private _empresaservice:EmpresaService
    ){
        this.title = 'Asignar Areas a encuesta';
        this.token = this._usuarioService.getToken();
        this.inicializarClase();
        this.GRIDlistar();
}

/**
 * Métodos
 */

 //
 //https://stackblitz.com/edit/demo-ngxdatatable-enable-editing-for-row-on-button-click?file=src%2Fapp%2Fapp.component.html
 inicializarClase(){
    this.encuestaarea = new EncuestaArea(0,0,this.area,0,this.encuesta,0,this.empresa,1,new Date(),true,new Date(),new Date(),"");
    return this.encuestaarea;
 }

ngOnInit(){
    console.log('Componente admin encuesta area cargado');
    this.obtenerAreas(this.token);
    this.obtenerEncuestas(this.token);
    this.obtenerEmpresas(this.token);
    this.token = this._usuarioService.getToken();

    this.columns = [
        { name:'ID',prop: 'ID_ENCUESTA'},
        { name:'DESCRIPCIÓN', prop: 'DESCRIPCION'},
        { name:'EMPRESA', prop: 'EMPRESA'},
        { name:'ESTATUS', prop:'ESTATUS'}
    ]
}

btn_nuevo(){
    this.inicializarClase();
    this.selectedValueEncuesta = this.encuestas[0];
    this.encuestaarea.Encuesta = this.selectedValueEncuesta;
    //console.log("btn_nuevo(): " + JSON.stringify(this.preguntareconocimiento));
    this.selectedValueArea = this.areas[0];
    this.encuestaarea.Area = this.selectedValueArea;

    this.selectedValueEmpresa = this.empresas[0];
    this.encuestaarea.Empresa = this.selectedValueEmpresa;
    $('#exampleModal').modal('show');
}

GRIDlistar(){
    this._encuestaareaservice.GRIDlistar(this.token).subscribe(
        response =>{
            console.log(JSON.stringify(response.items));
            this.rows = response.items;
            this.temp = response.items;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

obtenerEncuestas(token){
    this._encuestaService.obtenerActivas(token).subscribe(
        response =>{
            this.encuestas = response.items;
            console.log("log de obtener encuestas: " + JSON.stringify(this.encuestas));
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

obtenerAreas(token){
    this._areaService.obtenerActivas(token).subscribe(
        response =>{
            this.areas = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

obtenerEmpresas(token){
    this._empresaservice.obtenerActivas(token).subscribe(
        response =>{
            this.empresas = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

selectedValueEncuesta: any;
selectedValueArea: any;
selectedValueEmpresa: any;

doSomethingEncuesta() {
    //console.log(this.selectedValue);
    console.log(this.selectedValueEncuesta);

    this.encuestaarea.Encuesta = this.selectedValueEncuesta;
    this.obtenerAreaDeEncuesta(this.encuestaarea.Encuesta.ID,this.encuestaarea.Empresa.ID);
    console.log(JSON.stringify(this.encuestaarea));
    //console.log(JSON.stringify("dosomething()" + this.usuario.Rol));
}// setNewUser()

doSomethingArea() { 
    console.log(this.selectedValueArea);
    this.encuestaarea.Area = this.selectedValueArea;
    //this.obtenerTerminalesPorSucursal(this.equipo.Sucursal.ID,this.token);
}// setNewUser()

    /*onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }*/

doSomethingEmpresa(){
    this.encuestaarea.Empresa = this.selectedValueEmpresa;
    this.obtenerAreaDeEncuesta(this.encuestaarea.Encuesta.ID,this.encuestaarea.Empresa.ID);
}
    
    onActivate(event) {
        var a = event;

        if(event.type== 'click'){
            this.status = "";
            this.encuesta_seleccionada = event.row;
            console.log("LOG DE ENCUESTA_AREA SELECCIONADA: " + JSON.stringify(this.encuesta_seleccionada));
            this.selectedValueEncuesta = this.encuestas.find(x => x.ID === this.encuesta_seleccionada.ID_ENCUESTA);
            this.selectedValueArea = this.areas[0];
            this.selectedValueEmpresa = this.empresas.find(x => x.ID === this.encuesta_seleccionada.ID_EMPRESA);
            this.encuestaarea.Encuesta = this.selectedValueEncuesta;
            this.encuestaarea.Area = this.selectedValueArea;
            this.encuestaarea.Empresa = this.selectedValueEmpresa;
            this.obtenerAreaDeEncuesta(this.encuesta_seleccionada.ID_ENCUESTA,this.encuesta_seleccionada.ID_EMPRESA);
            $('#exampleModal').modal('show');
        }
        //console.log(JSON.stringify(event));
    /*if(event.type == 'click') {



        //this.selectedValuePregunta = this.preguntas.find(x => x.ID === this.pregunta_seleccionada.ID);
        ////this.selectedValueRespuesta = this.respuestas.find(x => x.ID === this.preguntarespuesta.ID_RESPUESTA);
        //this.obtenerRespuestasDePregunta(this.pregunta_seleccionada.ID);
        $('#exampleModal').modal('show');
    }*/
    //alert("soy un event");
}

eliminar(element){
    //var ID_ENCUESTA = this.encuestaarea.Encuesta.ID;
    var ID_ENCUESTA_AREA = element;
    this._encuestaareaservice.eliminar(ID_ENCUESTA_AREA,this.token).subscribe(
        response =>{
            this.obtenerAreaDeEncuesta(this.encuesta_seleccionada.ID_ENCUESTA,this.encuesta_seleccionada.ID_EMPRESA);
            this.GRIDlistar();
            console.log("Respuesta eliminada correctamente");
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

agregar(){
    //console.log(JSON.stringify(this.preguntarespuesta));
    //console.log("LOG DE AGREGAR: " + JSON.stringify(this.encuestaarea));
    this._encuestaareaservice.gestion(this.encuestaarea,this.token).subscribe(
        response=>{
            this.obtenerAreaDeEncuesta(this.encuestaarea.Encuesta.ID,this.encuestaarea.Empresa.ID);
            this.GRIDlistar();
            console.log("Respuesta agregada correctamente");
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

obtenerAreaDeEncuesta(ID_ENCUESTA,ID_EMPRESA){
    this._encuestaareaservice.obtenerAreasDeEncuesta(ID_ENCUESTA,ID_EMPRESA,this.token).subscribe(
        response=>{
            this.areasenencuesta = response.items;
            console.log("log de AREAS EN ENCUESTA: " + JSON.stringify(this.areasenencuesta));
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

onSubmit(){
    this._encuestaService.gestion(this.encuesta,this.token).subscribe(
        response =>{
            $('#txtBuscar').val('');
            this.GRIDlistar();
            this.formValues.resetForm(); // Added this
            this.status = "";
            $('#exampleModal').modal('hide');
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();   
    // filter our data
    const temp = this.temp.filter(function(d) {
        return d.DESCRIPCION.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}
}
//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { Terminal } from '../../models/terminal';
import { Sucursal } from '../../models/sucursal';
import { SucursalService } from '../../services/sucursal.service';
import { TerminalService } from '../../services/terminal.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var $:any;

@Component({
    selector:'admin-terminal',
    templateUrl:'./admin-terminal.component.html',
    providers:[UsuarioService,SucursalService,TerminalService],
    //styleUrls:['../']
})

export class AdminTerminalComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    public terminal:Terminal;
    public status:string;
    public errorTexto:string;
    fromDate;

    public sucursales : Sucursal[];

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private _usuarioService: UsuarioService,
        private _sucursalservice: SucursalService,
        private _terminalservice:TerminalService
    ){
        this.title = 'Catálogo de terminales';
        this.token = this._usuarioService.getToken();
        this.inicializarClase();
        this.GRIDlistar();
}

/**
 * Métodos
 */

 inicializarClase(){
    this.terminal = new Terminal();
    this.terminal.ID = 0;
    this.terminal.DESCRIPCION = "";
    this.terminal.NUM_SERIE = "";
    this.terminal.ESTATUS = true;
    return this.terminal;
 }
ngOnInit(){
    console.log('Componente empresa cargado');
    this.obtenerSucursales(this.token);
    this.token = this._usuarioService.getToken();

    this.columns = [
        { Name:'ID',prop: 'ID'},
        { name:'DESCRIPCIÓN', prop: 'DESCRIPCION'},
        { name:'# SERIE', prop: 'NUM_SERIE' },
        { name:'SUCURSAL', prop: 'Sucursal.DESCRIPCION' },
        { name:'ESTATUS', prop:'estatus_texto'}
    ]
}

obtenerSucursales(token){
    this._sucursalservice.obtenerSucursalesActivas(token).subscribe(
        response =>{
            this.sucursales = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

btn_nuevo(){
    this.inicializarClase();
    this.selectedValue= this.sucursales[0];
    $('#exampleModal').modal('show');
}

selectedValue: any;
doSomething() {
    //console.log(this.selectedValue);
    this.terminal.Sucursal = this.selectedValue;
}// setNewUser()

GRIDlistar(){
    this._terminalservice.GRIDlistarTerminales(this.token).subscribe(
        response =>{
            this.rows = response.items;
            this.temp = response.items;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

    /*onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }*/
    
    onActivate(event) {
    if(event.type == 'click') {
        this.status = "";
        this.terminal = event.row;
        this.selectedValue = this.sucursales.find(x => x.ID === this.terminal.ID_SUCURSAL);
        $('#exampleModal').modal('show');
    }
}

obtenerTerminalPorId(ID_TERMINAL,token){
    this._terminalservice.terminalBuscarPorId(ID_TERMINAL,token).subscribe(
        response =>{
            this.terminal = response.item;
            $('#exampleModal').modal('show');
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    )
}

onSubmit(){
    this.terminal.FEC_REG = new Date();
    this.terminal.HHMMSS_MOD = new Date();
    this._terminalservice.terminalGestion(this.terminal,this.token).subscribe(
        response =>{
            $('#txtBuscar').val('');
            this.GRIDlistar();
            this.formValues.resetForm(); // Added this
            this.status = "";
            $('#exampleModal').modal('hide');
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();   
    // filter our data
    const temp = this.temp.filter(function(d) {
        return d.DESCRIPCION.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}
}
import { Pregunta } from './pregunta';
import { Respuesta } from './respuesta';

export class PreguntaRespuesta{
    constructor(
        public ID:number,
        public ID_PREGUNTA:number,
        public Pregunta:Pregunta,
        public ID_RESPUESTA:number,
        public Respuesta:Respuesta,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string,
    ){

    }
}

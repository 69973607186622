import { Injectable } from '@angular/core';
import { CanActivate,ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UsuarioService } from '../../services/usuario.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _usuarioservice:UsuarioService, private router:Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //return true;
    console.log("entrando a can activate");
    var bandera = true;
    if(this._usuarioservice.isLoggedIn()){
      return true;
    }
    else{
      this.router.navigate(["/login"]);
      return false
    }
  }

  /*CanActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //return true;

    console.log("entrando a can activate child");

    if(this.usuarioservice.isLoggedIn()){
      return true;
    }
    else{
      this.router.navigate(["/login"]);
      return false
    }
  }*/

  
}

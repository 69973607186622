import { Terminal } from './Terminal';
import { Sucursal } from './Sucursal'
import { Equipo } from './equipo';
import { Usuario } from './usuario';
import { NgFormSelectorWarning } from '@angular/forms';

export class EquipoSeleccionado{

    public ID:number;
    public ID_EQUIPO: number;
    public Equipo:Equipo;
    public ID_USUARIO:number;
    public Usuario:Usuario;
    public FEC_REG:Date;
    public ESTATUS:boolean;
    public FECHA_MOD:Date;
    public HHMMSS_MOD:Date;
    public CVE_USU_MOD:string
}

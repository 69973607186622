//https://codinglatte.com/posts/angular/building-http-interceptor-angular-5/
//https://github.com/mainawycliffe/angular-httpinterceptor
//https://stackoverflow.com/questions/50695321/angular-6-handling-of-403-response-with-new-rxjs
//https://ryanchenkie.com/angular-authentication-using-the-http-client-and-http-interceptors
//http://musttoknow.com/use-interceptor-angular-error-handling-angular-authentication-using-http-client-http-interceptor/

import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor { 
    constructor(
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log("llamada interceptada");
        return next.handle(request).catch((err: any) => {
            if (err instanceof HttpErrorResponse) {
                if(err.status === 403){
                    console.log("ocurrio un error 403");
                    localStorage.clear();
                    //this.identity=null;
                    this.router.navigate(['/login']);

                    // this response is handled
                    // stop the chain of handlers by returning empty
                    //return Observable.empty();
                }
            }

            // rethrow so other error handlers may pick this up
            return Observable.throw(err);
        });
    }
}
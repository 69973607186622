import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { Empresa } from '../models/empresa'; //
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class EmpresaService{
    public url:string; //url de nuestro backend

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    empresaAgregar(empresa: Empresa): Observable<any>{
        //hay que checar la parte de los tokens
        let params = JSON.stringify(empresa);
        let headers = new HttpHeaders().set('Content-Type','application/json');

        return this._http.post(this.url+'empresa-agregar',params, {headers:headers});
    }

    empresaModificar(empresa: Empresa):Observable<any>{
        let params = JSON.stringify(empresa);
        let headers = new HttpHeaders().set('Content-Type','application/json');

        return this._http.put(this.url+'empresa-modificar',params,{headers:headers});
    }

    empresaGestion(empresa: Empresa,token:string): Observable<any>{
        let params = JSON.stringify(empresa);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        if(empresa.ID==0){
            return this._http.post(this.url+'empresa-agregar',params, {headers:headers});
        }
        else{
            return this._http.put(this.url+'empresa-modificar',params,{headers:headers});
        }
    }

    empresaBuscarPorId(id:number,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'empresa-obtener-id/'+id,{headers:headers});
    }

    empresaBuscarTodos(token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.get(this.url+'empresa-obtener-todos',{headers:headers});
    }

    obtenerActivas(token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});

        return this._http.get(this.url+'obtener-empresas-activas',{headers:headers});
    }
}
'use strict'
import { Component, OnInit, ɵConsole, Input } from '@angular/core';
import { UsuarioService } from '../../../services/usuario.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

import { MenuService } from '../../../services/menu.service';
import { Menu } from '../../../models/menu';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';

import { Usuario } from '../../../models/usuario';
import { HelperService } from '../../../services/helpers.service';

import { NavItem } from '../../../NavItem';

@Component({
    selector:'site-layout',
    templateUrl:'site-layout.component.html',
    providers:[UsuarioService,MenuService,HelperService]
})

export class SiteLayoutComponent implements OnInit{

    public identity;
    rawHtml: SafeHtml;
    public men :Menu;
    public menus:any[];
    public status:string;
    public errorTexto:string;
    public menuFiltrado:Menu[];
    public menuTexto:string;
    public contador:number;
    public conta:number;
    public usuario:Usuario;
    public token:string;

    @Input() private navItems:NavItem[];

    constructor(
        private _route: ActivatedRoute,
        private _router:Router,
        private _usuarioService: UsuarioService,
        private sanitizer: DomSanitizer,
        private _menuService: MenuService,
        private _helperservice:HelperService
    ){

    }

    //listado de iconos awesome icons
    //http://swwwitch.com/dl/Font-Awesome-Cheetsheet-4.5.0.pdf

    ngOnInit(){
        console.log('entro al metodo on init del site layout component');
        this.token = this._usuarioService.getToken();
        //this.validarAcceso(this.token);  
        this.identity = this._usuarioService.getIdentity();
        this.usuario = this._usuarioService.getIdentity();
        this.rawHtml = this.sanitizer.bypassSecurityTrustHtml('<h4>soy un menu, <b>letra en negrita</b></h4>');
        this.obtenerMenu();
    }

    validarAcceso(token){
      this._helperservice.acceso(token).subscribe(
          response=>{
              console.log("componente dashboard cargado");
          },
          err=>{
              var errorMessage = <any>err;
              console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
          }
      );
  }

    logOut(){
        localStorage.clear();
        this.identity=null;
        this._router.navigate(['/login']);
    }

    obtenerMenu(){
        //console.log(this.empresa);
        //loguear al usuario y conseguir sus datos
        this._menuService.obtenerMenu(this.token).subscribe(
            response =>{
              if(Object.keys(response.item).length === 0){
                this.menus = null;
            }
            else{
              //console.log("log de response: " + JSON.stringify(response.item));
                //console.log("log de empresaBuscarTodos: "+ JSON.stringify(response.items));
                //var string_items = JSON.stringify(response.items);
                //this.menus = response.item;
                this.navItems = response.item;
                this.menus = response.item;
                //console.log("log de navitems: " + this.navItems);
                //this.menuFiltrado = this.menu;
                this.menuTexto="";
                //this.mostrarMenu(this.menu,0);
                //console.log("LOG DE MENU:"+ JSON.stringify(this.menus));
                //this.rows = JSON.parse(string_items);
                //console.log(this.rows);
                //cb(JSON.parse(response));
                //this.status = 'success';
                //cb = response;
                //this.empresas = response.items;
                //console.log("log de this.empresas " + JSON.stringify(response.items));
                //this.rows = response.items;
                //this.temp = response.items;
                //console.log("valor de this.rows"+ JSON.stringify(this.rows))
            }
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    mostrarMenu(menu, idMenu){
        console.log("datos de menu: " + menu);
        console.log("datos de idmenu: " + idMenu)
        //this.menu=this.menu.find(x => x.PADRE== idMenu);
        //this.menuFiltrado = menu.find(x => x.PADRE== idMenu);
        var menuFiltro = menu.find(x => x.PADRE== idMenu);
        console.log("datos de menuFiltro: " + menuFiltro);
        //this.contador = menu.filter(x=>x.PADRE==idMenu).;
        //console.log("datos de menu filtrado: " + JSON.stringify(this.menuFiltrado) );//+ JSON.stringify(menu.find(x => x.PADRE== idMenu)));
        console.log("descripcion de menu filtrado " + menuFiltro.DESCRIPCION);
        //console.log("count de menufiltrado: " + Object.keys(this.menuFiltrado).length);

        //console.log("count de menufiltrado: " + this.contador);
          //console.log(this.menuTexto);

          console.log(Object.prototype.toString.call(menuFiltro));
          if(Object.prototype.toString.call(menuFiltro) === '[object Object]') //1 objeto
          {
            console.log("es un objeto");
            this.menuTexto +=
            '<ul>'+
                '<li>'+
                menuFiltro.DESCRIPCION
                    //this.menuFiltrado[0].DESCRIPCION+
                    'texto descrpcion'
                '</li>'+
                this.mostrarMenu(menu,menuFiltro.ID)
            '<ul>'
          }
          else{
            console.log("es un listado");
            for (let item of menuFiltro) {
                console.log("entre al log");
                console.log("FOR menu filtrado: " + JSON.stringify(item.DESCRIPCION));
                this.menuTexto +=
                '<ul>'+
                    '<li>'+
                        item.DESCRIPCION+
                    '</li>'+
                    this.mostrarMenu(menu,item.ID)
                '<ul>'
            } 
          }  
     
          /*var forEach = function (menuFiltrado, callback, scope) {
            if (Object.prototype.toString.call(menuFiltrado) === '[object Object]') {
                for (var prop in menuFiltrado) {
                  if (Object.prototype.hasOwnProperty.call(menuFiltrado, prop)) {
                    callback.call(scope, menuFiltrado[prop], prop, menuFiltrado);
                    console.log(prop);
                  }
                  console.log(prop);
                }
              } else {
                for (var i = 0; i < menuFiltrado.length; i++) {
                  callback.call(scope, menuFiltrado[i], i, menuFiltrado);
                  console.log(menuFiltrado[i]);
                }
              }
          }*/

          /*for (let key of Object.keys(this.menuFiltrado)) {  
            let mealName = this.menuFiltrado[key];
            console.log(mealName);
          }*/
          
          /*menu.find(x => x.PADRE== idMenu).forEach(function (arrayItem) {
            var x = arrayItem;
            console.log("log de x: " +JSON.stringify(x.DESCRIPCION));
        });*/

         /* for(var i =0;i<this.contador;i++){
              console.log("entre al for");
            this.men = this.menuFiltrado[i+1];*/

            //this.menuTexto +='<ul>'+
              //  '<li>'+
                //    this.menuFiltrado[i]. +
                //'</li>'+
            //this.mostrarMenu(this.menuFiltrado,this.menuFiltrado[i].ID)
            //'<ul>'
            //console.log("log de id: " +this.men);
            //this.min = this.items.list[i].main;
            //console.log(this.min);
          //}
          //console.log("log de menu texto: " + this.menuTexto);
    }
}
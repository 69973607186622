import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { Cliente } from '../models/cliente'; //
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class EquipoFolioService{
    public url:string; //url de nuestro backend

    public folioLocalStorage;
    public EquipoTerminalToken;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    //si la encuesta para el folio en cuestion se crea correctamente ya no aparece en el listado
    actualizarFolio(Folio:string): Observable<any>{
        console.log("entre al metodo clienteporfolio ");
        //hay que checar la parte de los tokens
        //var folioJSON = { folio: folio_parametro }
        //let params = JSON.stringify(folioJSON);
        let headers = new HttpHeaders().set('Content-Type','application/json');
        //console.log("valor de los parametros: " + params);
        return this._http.post(this.url+'folio-contestado-actualizar/' + Folio, {headers:headers});
    }

    obtenerClientePorFolioEquipoSucursal(FOLIO:string, ID_SUCURSAL: string ):Observable<any>{
        console.log("entre al metodo obtenerClientePorFolioEquipoSucursal ");
        //hay que checar la parte de los tokens
        var folioJSON = { FOLIO: FOLIO, ID_SUCURSAL: ID_SUCURSAL }
        let params = JSON.stringify(folioJSON);
        let headers = new HttpHeaders().set('Content-Type','application/json');
        //console.log("valor de los parametros: " + params);
        return this._http.post(this.url+'obtener-cliente-por-folio-equipo-sucursal',params, {headers:headers});
    }

    obtenerClientePorFolioEquipo(FOLIO:string):Observable<any>{
        console.log("entre al metodo obtenerClientePorFolioEquipo");
        //hay que checar la parte de los tokens
        let headers = new HttpHeaders().set('Content-Type','application/json');
        //console.log("valor de los parametros: " + params);
        return this._http.post(this.url+'obtener-cliente-por-folio-equipo/'+FOLIO, {headers:headers});
    }

    validarToken(TOKENURL:string,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);
        //console.log("valor de los parametros: " + params);
        return this._http.post(this.url+'validar-token/' + TOKENURL,null, {headers:headers});
    }

    validarFolio(FOLIO:string,token:string):Observable<any>{
        console.log("entro al metodo validar folio");
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);
        //console.log("valor de los parametros: " + params);
        return this._http.post(this.url+'validar-folio/' + FOLIO,null, {headers:headers});
    }
}
//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Empresa } from '../../models/empresa';
import { EmpresaService } from '../../services/empresa.service';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UploadService } from '../../services/upload.service';
import { UsuarioService } from '../../services/usuario.service';
import { GLOBAL } from '../../services/global';

//import {DatatableComponent} from '../../src/components/datatable.component';

declare var $:any;

@Component({
    selector:'admin-empresa',
    templateUrl:'./admin-empresa.component.html',
    providers:[EmpresaService,DatePipe, UploadService,UsuarioService],
    //styleUrls:['../']
})

export class AdminEmpresaComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    public empresa:Empresa;
    public status:string;
    public errorTexto:string;
    fromDate;

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;

    public url: string;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private _route:ActivatedRoute,
        private _router:Router,
        private _empresaService: EmpresaService,
        private datePipe: DatePipe,
        private _uploadService: UploadService,
        private _usuarioService: UsuarioService
    ){
        this.title = 'Catálogo de empresas';
        this.url = GLOBAL.url;
        this.token = this._usuarioService.getToken();
        this.inicializarClase();

        //setTimeout(() => { this.loadingIndicator = false; }, 5000);
        /*console.log("sigo despues del timeout")
        this.empresaBuscarTodos((data) => {
            //console.log("datos de data: " +  data)
          this.rows = data.items;
        });*/

  /*this.empresaBuscarTodos((data)=>{
    console.log("entre al metodo buscar todos");
    console.log("data de empresaBuscarTodos:" +data);
      this.rows = data;
  });*/

  this.empresaBuscarTodos();
  
}

/**
 * Métodos
 */

 inicializarClase(){
    this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    $("#fileEmpresa").val('');
    return this.empresa = new Empresa(
        0,
        "",
        "",
        "",
        this.fromDate,
        true,
        new Date(),
        new Date(),
        ""
    )
 }
    ngOnInit(){
        //this.empresaBuscarTodos();
        console.log('Componente empresa cargado');
        this.token = this._usuarioService.getToken();
        //console.log(this.empresaBuscarTodos());

        this.columns = [
            { Name:'ID',prop: 'ID'},
            { name:'DESCRIPCIÓN', prop: 'DESCRIPCION'},
            { name:'DESCRIPCIÓN LARGA', prop: 'DESCRIPCION2' },
            { name:'ESTATUS', prop:'estatus_texto'}
            /*{ prop: 'name' },
            { name: 'Gender' }*/
        ]

        /*$('#btnNuevaEmpresa').click(function(){
            this.formValues.reset(); // Added this
            this.status = "";
            console.log("log de la empresa con datos: " + JSON.stringify(this.empresa));
            this.empresa = null;
            $( "input[name='DESCRIPCION']" ).val('');
            console.log("log de la empresa sin datos: " + JSON.stringify(this.empresa));
            $('#exampleModal').modal('show');
        });*/
    }

    btn_nuevaEmpresa_click(){
        //this.limpiarControles();
        this.inicializarClase();
        //this.eraseCode(); //si dejo erasecode entonces la inicializacion de mi objeto se borra
        //this.fromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        $('#exampleModal').modal('show');
    }



    empresaBuscarTodos(){
        //console.log(this.empresa);
        //loguear al usuario y conseguir sus datos
        this._empresaService.empresaBuscarTodos(this.token).subscribe(
            response =>{
                //console.log("log de empresaBuscarTodos: "+ JSON.stringify(response.items));
                var string_items = JSON.stringify(response.items);
                //this.rows = JSON.parse(string_items);
                //console.log(this.rows);
                //cb(JSON.parse(response));
                //this.status = 'success';
                //cb = response;
                //this.empresas = response.items;
                //console.log("log de this.empresas " + JSON.stringify(response.items));
                this.rows = response.items;
                this.temp = response.items;
                console.log("valor de this.rows"+ JSON.stringify(this.rows))
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
        //this.empresa.ID = selected
        
      }
    
      onActivate(event) {
        //console.log('Activate Event', event);
        if(event.type == 'click') {
            //this.formValues.resetForm(); // Added this
            this.status = "";
            console.log('log del row '+ JSON.stringify(event.row));
            this.empresa = event.row;
            //this.empresa.FEC_REG = new Date();
            //this.FEC_REG = this.datePipe.transform(this.empresa.FEC_REG.toISOString(),'yyyy-MM-dd');
            //this.FEC_REG = this.datePipe.transform(this.empresa.FEC_REG, 'yyyy-MM-dd');
            this.fromDate = this.datePipe.transform(this.empresa.FEC_REG, 'yyyy-MM-dd');
            $("#fileEmpresa").val('');
            console.log(this.empresa.FEC_REG)
            console.log(new Date());
            console.log('datos de la empresa ' + JSON.stringify(this.empresa));
            console.log($('#FEC_REG').val());
            console.log($('#fromDate').val());
            console.log('valor input hidden :' + $('#ID_HIDDEN').val());
            //alert('ok');
            $('#exampleModal').modal('show');
        }
    }

    onSubmit(){
        //console.log(this.empresa);
        //loguear al usuario y conseguir sus datos
        this.empresa.FEC_REG = this.fromDate;
        this._empresaService.empresaGestion(this.empresa,this.token).subscribe(
            response =>{
                //console.log("log de empresaBuscarTodos: "+ JSON.stringify(response.items));
                //var string_items = JSON.stringify(response.items);
                //this.rows = JSON.parse(string_items);
                //console.log(this.rows);
                //cb(JSON.parse(response));
                //this.status = 'success';
                //cb = response;
                //this.empresas = response.items;
                //console.log("log de this.empresas " + JSON.stringify(response.items));
                //this.rows = response.items;
                console.log("ya tuve respuesta de la primera insercción");
                console.log("id de empresa: " + this.empresa.ID);
                console.log("datos de response : " + JSON.stringify(response));

                var idEmpresa;
                //this.empresa = response.newEmpresa;
                if(this.empresa.ID ==0){
                    idEmpresa = response.newEmpresa.ID;
                }
                else{
                    idEmpresa = this.empresa.ID;
                }

                //SUBIDA DE IMAGEN DE EMPRESA
                this._uploadService.makeFileRequest(this.url+'empresa-subir-imagen/'+idEmpresa,[],this.filesToUpload,this.token,'image')
                    .then((result:any)=>{
                        console.log('resultados del makefilerequest' + result);
                        this.empresa.IMAGEN = result.updatedEmpresa.IMAGEN;
                    })

                $('#txtBuscarEmpresa').val('');
                this.empresaBuscarTodos();
                this.formValues.resetForm(); // Added this
                this.status = "";
                $('#exampleModal').modal('hide');
                //this._router.navigate(['/empresa']);
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();   
        // filter our data
        const temp = this.temp.filter(function(d) {
          return d.DESCRIPCION.toLowerCase().indexOf(val) !== -1 || !val;
        });
        // update the rows
        this.rows = temp;
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }

    public filesToUpload: Array<File>;
    fileChangedEvent(fileInput:any){
        this.filesToUpload = <Array<File>>fileInput.target.files;
        console.log('log de filestoupload: ' + this.filesToUpload);
    }
}
import { Component, OnInit } from '@angular/core';
import { GLOBAL } from '../../services/global';
import { Router } from '@angular/router';

import { ClienteService} from '../../services/cliente.service';
import { EquipoFolioService } from '../../services/equipofolio.service';
import { UsuarioService} from '../../services/usuario.service';
import { FolioAreaService } from '../../services/folioarea.service';

declare var $:any;

@Component({
    selector:'toma-muestra',
    templateUrl:'toma-muestra.component.html',
    providers:[ClienteService, EquipoFolioService,FolioAreaService]
})

export class TomaMuestraComponent implements OnInit{

    public title:string;
    public url:string;
    public identity;
    public estudio=[];
    private token:string;

    constructor(private _clienteservice: ClienteService,        
                private _router:Router,
                private _equipofolioservice: EquipoFolioService,
                private _usuarioservice:UsuarioService,
                private _folioareaservice:FolioAreaService
                ){
        this.title = 'Toma de muestra';
        this.url = GLOBAL.url;
    }

    ngOnInit(){
        this.token = this._usuarioservice.getToken();
        //this.identity = this._usuarioservice.getIdentity();
    }

    redireccionar_(){
        console.log("entre a metodo redireccionar");
        var folio = $('#txtFolio').val();
        console.log('log del folio: ' + folio);
        this._router.navigate(['/verificar-datos'], {queryParams:{folio:folio}})
    }

    redireccionar(url){
        this._router.navigate([url])
    }

    irVerificarDatos(folio){
        var url = "verificar-datos/" + folio;
        this.redireccionar(url);
    }

    //se encarga de verificar que en el campo de folio no este vacío
    validarFolio(){
        if($('#txtFolio').val().length===0){
            console.log("esta vacio el campo y no entre al metodo");

            $.gritter.add({
                // (string | mandatory) the heading of the notification
                title: 'Ingrese un folio para continuar',
                // (string | mandatory) the text inside the notification
                text: 'El campo Folio Cliente esta vacío.',
                class_name: 'gritter-error'
            });
            return;
        }
        this.validarFolioBackend();
        //this.validarFolioContestado();
    }

    validarFolioBackend(){
        let FOLIO = $('#txtFolio').val();
        console.log("ENTRE A METODO VALIDAR FOLIO");
        this._equipofolioservice.validarFolio(FOLIO,this.token).subscribe(
            response=>{
                if(response.item == null){
                    //this.redireccionar("404");
                    $.gritter.add({
                        // (string | mandatory) the heading of the notification
                        title: 'Folio no válido',
                        // (string | mandatory) the text inside the notification
                        text: 'El Folio ya fue contestado o no existe.',
                        class_name: 'gritter-error'
                    });
                }
                else{
                    this.irVerificarDatos(FOLIO);
                }
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    validarFolioContestado(){
        let FOLIO = $('#txtFolio').val();
        let ID_AREA = this.identity.Area.ID;

        this._folioareaservice.obtenerFolioArea(FOLIO,ID_AREA).subscribe(
            response =>{
                if(response.result){
                    this.obtenerClientePorFolioEquipoSucursal();
                }
                else{
                    $.gritter.add({
                        // (string | mandatory) the heading of the notification
                        title: 'Folio no válido',
                        // (string | mandatory) the text inside the notification
                        text: 'El Folio ya fue contestado.',
                        class_name: 'gritter-error'
                    });
                }
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        );
    }

    obtenerClientePorFolioEquipoSucursal(){
        console.log("entre al metodo obtenerClientePorFolioEquipoSucursal");

        let FOLIO = $('#txtFolio').val();
        let ID_SUCURSAL = this.identity.Sucursal.ID;

        console.log("FOLIO: " + FOLIO);
        console.log("ID_SUCURSAL: " + ID_SUCURSAL);
        //console.log(this.empresa);
        //loguear al usuario y conseguir sus datos
        this._equipofolioservice.obtenerClientePorFolioEquipoSucursal(FOLIO,ID_SUCURSAL).subscribe(
        response =>{

        //this.folioLocalStorage = this._clienteservice.getFolioLocalStorage();
        //var folio = response.item.FOLIO;

        if(response.item===null){
            //this.cliente = null;
            console.log("mostrar mensaje gritter de que el folio no es valido (warning)");
            $.gritter.add({
                // (string | mandatory) the heading of the notification
                title: 'Folio no válido',
                // (string | mandatory) the text inside the notification
                text: 'El folio no es válido para esta sucursal, verifíque.',
                class_name: 'gritter-error'
            });
    
            return false;
            //limpio datos
        }
        else{
            //mando a llamar al metodo redireccionar
            console.log("mando a llamar al metodo redireccionar");
            this.redireccionar("");
        }
    },
    err =>{
        var errorMessage = <any>err;
        console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
        //limpio datos
    }
);}}
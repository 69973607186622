import { Component, OnInit } from '@angular/core';
import { GLOBAL } from '../../services/global';
import { Router } from '@angular/router';
import { UsuarioService} from '../../services/usuario.service';
import { HelperService } from '../../services/helpers.service';
import { TipoService } from '../../services/tipo.service';
import { Tipo } from '../../models/tipo';


@Component({
    selector:'home',
    templateUrl:'home.component.html',
    providers:[UsuarioService,HelperService,TipoService]
})

export class HomeComponent implements OnInit{


    public title:string;
    public url:string;
    public identity;
    public token;
    public tipo:Tipo;

    constructor(private _usuarioservice: UsuarioService, 
                private _helperservice: HelperService,
                private _tiposervice:TipoService,       
                private _router:Router
                ){
        this.title = 'Inicio';
        this.url = GLOBAL.url;
    }

    ngOnInit(){
        this.token = this._usuarioservice.getToken();
        this.validarAcceso(this.token);  
    }

    validarAcceso(token){
        this._helperservice.acceso(token).subscribe(
            response=>{
                console.log("componente dashboard cargado");
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        );
    }

    //BUENO
    /*evaluarServicio(){
        this._tiposervice.obtenerUrl(this.token).subscribe(
            response=>{
                this.redireccionar(response.item.URL_CONFIGURACION_INICIAL);
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }*/

    evaluarServicio(url){
        console.log(url);
        this.redireccionar(url);
    }

    redireccionar(ruta){ 
        this._router.navigate([ruta]);
    }
}
//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { Area } from '../../models/area';
import { Terminal } from '../../models/terminal';
import { Tipo } from '../../models/tipo';
import { AreaService } from '../../services/area.service';
import { TipoService } from '../../services/tipo.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var $:any;

@Component({
    selector:'admin-area',
    templateUrl:'./admin-area.component.html',
    providers:[UsuarioService,AreaService,TipoService],
    //styleUrls:['../']
})

export class AdminAreaComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    //public terminal:Terminal;
    public area:Area;
    public status:string;
    public errorTexto:string;
    fromDate;

    public tipos : Tipo[];

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private _usuarioService: UsuarioService,
        private _areaservice:AreaService,
        private _tiposervice:TipoService
    ){
        this.title = 'Catálogo de áreas';
        this.token = this._usuarioService.getToken();
        this.inicializarClase();
        this.GRIDlistar();
}

/**
 * Métodos
 */

 inicializarClase(){
    this.area = new Area();
    this.area.ID = 0;
    this.area.DESCRIPCION = "";
    this.area.ESTATUS = true;
    return this.area;
 }
ngOnInit(){
    console.log('Componente empresa cargado');
    this.obtenerTipos(this.token);
    this.token = this._usuarioService.getToken();

    this.columns = [
        { Name:'ID',prop: 'ID'},
        { name:'DESCRIPCIÓN', prop: 'DESCRIPCION'},
        { name:'TIPO', prop: 'Tipo.DESCRIPCION' },
        { name:'ESTATUS', prop:'estatus_texto'}
    ]
}

obtenerTipos(token){
    this._tiposervice.obtenerActivas(token).subscribe(
        response =>{
            this.tipos = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

btn_nuevo(){
    this.inicializarClase();
    this.selectedValue= this.tipos[0];
    console.log(JSON.stringify("btnnuevo()" + this.area.Tipo));
    $('#exampleModal').modal('show');
}

selectedValue: any;
doSomething() {
    //console.log(this.selectedValue);
    this.area.Tipo = this.selectedValue;
    console.log(JSON.stringify("dosomething()" + this.area.Tipo));
}// setNewUser()

GRIDlistar(){
    this._areaservice.GRIDlistarAreas(this.token).subscribe(
        response =>{
            this.rows = response.items;
            this.temp = response.items;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

    /*onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }*/
    
    onActivate(event) {
    if(event.type == 'click') {
        this.status = "";
        this.area = event.row;
        this.selectedValue = this.tipos.find(x => x.ID === this.area.ID_TIPO);
        $('#exampleModal').modal('show');
    }
}

onSubmit(){
    this.area.FEC_REG = new Date();
    this.area.HHMMSS_MOD = new Date();
    this._areaservice.gestion(this.area,this.token).subscribe(
        response =>{
            $('#txtBuscar').val('');
            this.GRIDlistar();
            this.formValues.resetForm(); // Added this
            this.status = "";
            $('#exampleModal').modal('hide');
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();   
    // filter our data
    const temp = this.temp.filter(function(d) {
        return d.DESCRIPCION.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}
}
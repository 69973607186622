//implementacion de ngxdatatable
//https://stackoverflow.com/questions/51303630/how-to-use-edit-and-delete-options-in-angular-ngx-datatables

//solucion para usar metodos de angular en html
//https://stackblitz.com/edit/angular-c6vfcq?file=src%2Fapp%2Fapp.component.html

//solución al asignar el input date 
//https://stackblitz.com/edit/angular-xuvrtq?file=src%2Fapp%2Fapp.component.ts

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { EmptyError } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { Usuario } from '../../models/usuario';
import { TipoCliente } from '../../models/tipocliente';
import { RolService } from '../../services/rol.service';
import { EmpresaService } from '../../services/empresa.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Empresa } from '../../models/empresa';
import { Rol } from '../../models/rol';
import { Area } from '../../models/area';
import { Sucursal } from '../../models/sucursal';
import { HelperService } from '../../services/helpers.service';

declare var $:any;

@Component({
    selector:'admin-usuario',
    templateUrl:'./admin-usuario.component.html',
    providers:[UsuarioService,RolService,EmpresaService,HelperService],
    //styleUrls:['../']
})

export class AdminUsuarioComponent implements OnInit{
    @ViewChild('addForm') formValues:NgForm; //added this
    public title:string;
    public usuario:Usuario;
    public status:string;
    public errorTexto:string;
    fromDate;

    public tiposcliente : TipoCliente[];
    public tipocliente: TipoCliente;
    public empresa: Empresa;
    public empresas: Empresa[];
    public rol:Rol;
    public roles:Rol[];
    public area:Area;
    public sucursal:Sucursal;

    public token;

    editing = {};
    rows = [];
    columns = [];
    selected=[];
    temp = [];

    loadingIndicator:boolean = true;
    reaorderable: boolean = true;

    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private _usuarioService: UsuarioService,
        private _rolservice: RolService,
        private _empresaservice:EmpresaService,
        private _helperservice:HelperService
    ){
        this.title = 'Catálogo de usuarios';
        this.token = this._usuarioService.getToken();
        this.inicializarClase();
        this.GRIDlistar();
}

/**
 * Métodos
 */

/*onKeydown(event) {
    console.log("todo bien");
    this.obtenerUsuarioCVE_USU(event);

  }*/

obtenerUsuarioCVE_USU(){
    var CVE_USU = $("input[name=CVE_USU]").val();
    console.log(CVE_USU);
    this._helperservice.obtenerUsuarioPorCVE_USU(CVE_USU,this.token).subscribe(
        response=>{
            this.usuario.CVE_USU = response.item[0].CVE_USU;
            this.usuario.CVE_COL = response.item[0].CVE_COL;
            this.usuario.NOMBRE_COMPLETO = response.item[0].NOMBRE;
            //console.log(JSON.stringify(response.item[0].CVE_USU));
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
} 

 inicializarClase(){
    this.usuario = new Usuario(0,"","",this.empresa,0,"",this.rol,0,new Date(),true,new Date(),new Date(),"","",this.area,this.sucursal);
    console.log("LOG INICIAR CLASE: " + JSON.stringify(this.usuario));
    return this.usuario;
 }
 
ngOnInit(){
    console.log('Componente admin-usuario cargado');
    this.obtenerRoles(this.token);
    this.obtenerEmpresas(this.token);
    this.token = this._usuarioService.getToken();

    this.columns = [
        { Name:'ID',prop: 'ID'},
        { name:'NOMBRE COMPLETO', prop: 'NOMBRE_COMPLETO'},
        { name:'ROL', prop: 'Rol.DESCRIPCION' },
        { name:'EMPRESA', prop: 'Empresa.DESCRIPCION' },
        { name:'ESTATUS', prop:'estatus_texto'}
    ]
}

obtenerRoles(token){
    this._rolservice.obtenerActivas(token).subscribe(
        response =>{
            this.roles = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

obtenerEmpresas(token){
    this._empresaservice.obtenerActivas(token).subscribe(
        response =>{
            this.empresas = response.items;
        },
        err=>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error'; 
            }
        }
    )
}

btn_nuevo(){
    this.inicializarClase();
    this.selectedValue = this.roles[0];
    this.usuario.Rol= this.selectedValue;
    this.selectedValueEmpresa = this.empresas[0];
    this.usuario.Empresa= this.selectedValueEmpresa;
    console.log(JSON.stringify("btnnuevo()" + JSON.stringify(this.usuario)));
    this.selectedValueEmpresa = this.empresas[0];
    $('#exampleModal').modal('show');
}

selectedValue: any;
selectedValueEmpresa: any;
doSomething() {
    //console.log(this.selectedValue);
    this.usuario.Rol = this.selectedValue;
    console.log(JSON.stringify("dosomething()" + this.usuario.Rol));
}// setNewUser()

doSomethingEmpresa() {
    console.log(this.selectedValueEmpresa);
    this.usuario.Empresa = this.selectedValueEmpresa;
    //this.obtenerTerminalesPorSucursal(this.equipo.Sucursal.ID,this.token);
}// setNewUser()

GRIDlistar(){
    this._usuarioService.GRIDlistar(this.token).subscribe(
        response =>{
            this.rows = response.items;
            this.temp = response.items;
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

    /*onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
      }*/
    
    onActivate(event) {
    if(event.type == 'click') {
        this.status = "";
        this.usuario = event.row;
        
        this.selectedValue = this.roles.find(x => x.ID === this.usuario.ID_ROL);
        this.selectedValueEmpresa = this.empresas.find(x => x.ID === this.usuario.ID_EMPRESA);
        console.log(JSON.stringify(this.usuario));
        $('#exampleModal').modal('show');
    }
}

onSubmit(){
    this._usuarioService.gestion(this.usuario,this.token).subscribe(
        response =>{
            $('#txtBuscar').val('');
            this.GRIDlistar();
            this.formValues.resetForm(); // Added this
            this.status = "";
            $('#exampleModal').modal('hide');
        },
        err =>{
            var errorMessage = <any>err;
            console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            this.errorTexto = errorMessage.error.message;
            if(errorMessage !=null){
                this.status = 'error';
            }
        }
    );
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();   
    // filter our data
    const temp = this.temp.filter(function(d) {
        return d.NOMBRE_COMPLETO.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
}
}
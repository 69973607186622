import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { GLOBAL } from './global'; // la url del API
import { EncuestaAreaPregunta } from '../models/encuestaareapregunta';

@Injectable()
export class EncuestaAreaPreguntaService{

    public url:string; //url de nuestro backend
    public encuestaArea:string;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    GRIDlistar(token:string): Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                        .set('Authorization',token);

        return this._http.get(this.url+'GRID-listar-encuestaareapreguntas', {headers:headers});
    }

    eliminar(ID_ENCUESTA_AREA_PREGUNTA:Number,token:string): Observable<any>{
        let params = JSON.stringify({ID_ENCUESTA_AREA_PREGUNTA:ID_ENCUESTA_AREA_PREGUNTA});
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                        .set('Authorization',token);

            return this._http.put(this.url+'encuestaareapregunta-eliminar',params, {headers:headers});
    }

    gestion(item: EncuestaAreaPregunta,token:string): Observable<any>{
        let params = JSON.stringify(item);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

            return this._http.post(this.url+'encuestaareapregunta-agregar',params, {headers:headers});
    }

    obtenerPreguntasDeEncuestaArea(ID_ENCUESTA:number,ID_EMPRESA:number,ID_AREA:number,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        //let params = JSON.stringify({ID_SUCURSAL:ID_SUCURSAL});
        //console.log("valor de id_pregunta: " + ID_PREGUNTA);

        return this._http.get(this.url+'obtener-preguntas-de-encuesta-area-pregunta/'+ID_ENCUESTA+'/'+ID_EMPRESA+'/'+ID_AREA,{headers:headers});
    }
}
import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { Evaluacion } from '../models/evaluacion'; //
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class EvaluacionService{
    public url:string; //url de nuestro backend
    public evaluacion:string;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    evaluacionAgregar(evaluacion: Evaluacion,token:string): Observable<any>{
        //hay que checar la parte de los tokens
        let params = JSON.stringify(evaluacion);
        //console.log("entre al log de evaluacionAgregar")
        //console.log(JSON.stringify(evaluacion));
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'evaluacion-agregar',params, {headers:headers});
    }

    evaluacionAgregarToken_(evaluacion: Evaluacion,token:string): Observable<any>{
        //hay que checar la parte de los tokens
        let params = JSON.stringify(evaluacion);
        //console.log("entre al log de evaluacionAgregar")
        //console.log(JSON.stringify(evaluacion));
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'evaluacion-agregar-token',params, {headers:headers});
    }
}
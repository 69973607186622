import { Area } from './area';
import { Encuesta } from './encuesta';
import { Empresa } from './empresa';

export class EncuestaArea{
    constructor(
        public ID:number,
        public ID_AREA: number,
        public Area:Area,
        public ID_ENCUESTA:number,
        public Encuesta:Encuesta,
        public ID_EMPRESA:number,
        public Empresa: Empresa,
        public ORDEN:number,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string
    ){

    }
}

import {Rol} from './rol';
import { Area } from './area';
import { Sucursal } from './sucursal';
import { Empresa } from './empresa';
//import {Deserializable} from './deserializable';

export class Usuario{
    constructor(
        public ID:number,
        public NOMBRE_COMPLETO:string,
        public IMAGEN:string,
        public Empresa:Empresa,
        public ID_EMPRESA:number,
        public CVE_COL:string,
        public Rol : Rol,
        public ID_ROL:number,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string,
        public CVE_USU:string,
        public Area:Area, //este sirve solo para los datos del login
        public Sucursal:Sucursal //este sirve solo para los datos del login
    ){

    }
}

/*export class Usuario implements Deserializable{
    public ID:number;
    public NOMBRE_COMPLETO:string;
    public IMAGEN:string;
    public CLAVE:string;
    public PASS:string;
    public ID_SUCURSAL:string;
    public ID_EMPRESA:number;
    public rol : Rol;
    public CAMPO1:string;
    public CAMPO2:string;
    public CAMPO3:string;
    public FEC_REG:Date;
    public ESTATUS:boolean;
    public FECHA_MOD:Date;
    public HHMMSS_MOD:Date;
    public CVE_USU_MOD:string;

    deserialize(input: any){
        Object.assign(this, input);
        //this.rol = new Rol().deserialize(input.rol);
        return this;
    }

}*/
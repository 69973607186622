import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { Rol } from '../../models/rol';
import { Empresa } from '../../models/empresa';
import { Sucursal } from '../../models/sucursal';
import { Area } from '../../models/area';
import { UsuarioService } from '../../services/usuario.service';
import { EncuestaAreaService } from '../../services/encuestaarea.service';
import { TipoService } from '../../services/tipo.service';

@Component({
    selector:'login',
    templateUrl:'login.component.html',
    providers: [UsuarioService,EncuestaAreaService,TipoService] //cargar los servicios que quiero tener disponibles en esta clase
})

export class LoginComponent implements OnInit{
    public title: string;
    public usuario:Usuario;
    public status:string;
    public errorTexto:string;
    public identity; // objeto del usuario identificado
    public token; // objeto del token
    public rol: Rol;
    public empresa: Empresa;
    public sucursal: Sucursal;
    public area: Area;
    public areas_login: Area[];
    public sucursales_login: Sucursal[];
    public area_seleccionada:Area;
    public sucursal_seleccionada:Sucursal;

    constructor(
        private _route:ActivatedRoute,
        private _router:Router,
        private _usuarioService: UsuarioService,
        private _encuestaAreaService: EncuestaAreaService,
        private _tiposervice:TipoService
    ){
        this.title = 'Inicio de sesión';
        this.usuario = new Usuario(
            0,
            "",
            "",
            this.empresa,
            0,
            "",
            this.rol,
            0,
            new Date(),
            true,
            new Date(),
            new Date(),
            "",
            "",
            this.area,
            this.sucursal
        )
    }
    ngOnInit(){
        console.log('componente del login cargado');
        localStorage.clear();
        this.obtenerAreasLogin();
        this.obtenerSucursalesLogin();
    }

    selectedValueArea: any;
    doSomethingArea() {
        //console.log(this.selectedValue);
        this.usuario.Area = this.selectedValueArea;
    }// setNewUser()

    obtenerAreasLogin(){
        this._usuarioService.obtenerAreasLogin().subscribe(
            response =>{
                this.areas_login = response.items;
                this.selectedValueArea = this.areas_login[0];
                this.usuario.Area = this.selectedValueArea;
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        )
    }

    selectedValueSucursal: any;
    doSomethingSucursal() {
        //console.log(this.selectedValue);
        this.usuario.Sucursal = this.selectedValueSucursal
    }// setNewUser()

    obtenerSucursalesLogin(){
        this._usuarioService.obtenerSucursalesLogin().subscribe(
            response =>{
                this.sucursales_login = response.items;
                this.selectedValueSucursal = this.sucursales_login[0];
                this.usuario.Sucursal = this.selectedValueSucursal;
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        )
    }

    onSubmit(){
        this._usuarioService.identificarUsuario(this.usuario).subscribe(
            response =>{
                //si el usuario existe asignamos el token generado al localStorage
                localStorage.setItem('token',JSON.stringify(response.token));
                console.log("proceso realizado correctaente");
                this.obtenerInformacionIdentity(response.token);
                this.evaluarServicio(response.token);
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        )
    }

    evaluarServicio(token){
        this._tiposervice.obtenerUrl(token).subscribe(
            response=>{
                this.redireccionar(response.item.URL_CONFIGURACION_INICIAL);
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        )
    }

    //trae la información del usuario para mostrar en la página
    obtenerInformacionIdentity(token){
        console.log(token);
        this._usuarioService.obtenerInformacionIdentity(token).subscribe(
            response =>{
                console.log("proceso realizado correctamente: obtenerInformacionIdentity");
                //si el usuario existe asignamos el identity generado al localStorage
                localStorage.setItem('identity',JSON.stringify(response.item[0]));
                //this.redireccionar();
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        )
    }

    /*redireccionar(){
        this._router.navigate(['/']);
    }*/

    redireccionar(ruta){ 
        this._router.navigate([ruta]);
    }

    /*onSubmit2(){
        console.log(this.usuario);
        //loguear al usuario y conseguir sus datos
        this._usuarioService.loguearUsuario(this.usuario).subscribe(
            response =>{
                console.log(JSON.stringify(response));
                //console.log("log de response usuario: " +response.usuario);
                this.identity = response.items;
                console.log('log de identity: ' + JSON.stringify(this.identity));
                if(!this.identity) {//|| !this.identity.ID){
                    this.status = "error";
                }
                else{
                    this.status = 'success';
                    // PERSISTIR DATOS DEL USUARIO
                    localStorage.setItem('identity',JSON.stringify(this.identity));
                    
                    //console.log("id de terminal: " + this.identity.Equipo.Terminal.ID);
                    //this.getEncuestaAreaPorTerminal(this.identity.Equipo.Terminal.ID);

                    //conseguir el token
                    this.getToken();
                    //console.log(this._usuarioService.isLoggedIn());
                    //this._router.navigate(['/']);
                }
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }*/

    /*getToken(){
        this._usuarioService.loguearUsuario(this.usuario, 'true').subscribe(
            response =>{
                //console.log(response.usuario);
                this.token = response.token;
                console.log('log del token: ' + this.token);

                if(this.token.length <=0){
                    this.status = "error";
                }
                else{
                    this.status = 'success';
                    // PERSISTIR DATOS DEL USUARIO
                    localStorage.setItem('token',this.token);
                    //conseguir el token

                    this._router.navigate(['/']);
                }
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }*/
}


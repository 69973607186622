import { Empresa } from './empresa';

export class Sucursal{

    public ID:number;
    public DESCRIPCION:string;
    public DESCRIPCION2:string;
    public Empresa:Empresa;
    public FEC_REG:Date;
    public ESTATUS:boolean;
    public FECHA_MOD:Date;
    public HHMMSS_MOD:Date;
    public CVE_USU_MOD:string;


    /*constructor(
        public ID:number,
        public DESCRIPCION:string,
        public DESCRIPCION2:string,
        public Empresa:Empresa,
        public FEC_REG:Date,
        public ESTATUS:boolean,
        public FECHA_MOD:Date,
        public HHMMSS_MOD:Date,
        public CVE_USU_MOD:string
    ){

    }*/
}

import { Component, OnInit } from '@angular/core';
import { GLOBAL } from '../../services/global';
import { Router, ActivatedRoute } from '@angular/router';

import { UsuarioService} from '../../services/usuario.service';
import { TerminalService} from '../../services/terminal.service';
import { HelperService } from '../../services/helpers.service';
import { EquipoSeleccionadoService } from '../../services/equiposeleccionado.service';
import { EquipoSeleccionado } from 'src/app/models/equiposeleccionado';

@Component({
    selector:'seleccionar-equipo',
    templateUrl:'seleccionar-equipo.component.html',
    providers:[TerminalService,HelperService,EquipoSeleccionadoService]
})

export class SeleccionarEquipoComponent implements OnInit{

    public title:string;
    public url:string;
    public identity;
    public terminales;
    public status:string;
    public errorTexto:string;
    public token; // objeto del token
    public equiposeleccionado: EquipoSeleccionado;

    constructor(private _usuarioservice: UsuarioService, 
                private _terminalservice:TerminalService,
                private _helperservice:HelperService,
                private _equiposeleccionadoservice:EquipoSeleccionadoService,    
                private _router:Router
                ){
        this.title = 'Seleccionar Equipo y Terminal';
        this.url = GLOBAL.url;
    }

    ngOnInit(){
        this.token = this._usuarioservice.getToken();
        this.validarAcceso(this.token);
        this.obtenerTerminalPorSucursal(this.token);  
    }

    validarAcceso(token){
        this._helperservice.acceso(token).subscribe(
            response=>{
                console.log("componente seleccionar equipo cargado");
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
            }
        );
    }

    obtenerTerminalPorSucursal(token){
        this._terminalservice.obtenerTerminalPorSucursal(token).subscribe(
            response =>{
                this.terminales = response.items; //[response.items[0].Pregunta,response.items[1].Pregunta];
                console.log("log de terminales: " + JSON.stringify(this.terminales));
            },
            err =>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }

    redireccionar(url){
        this._router.navigate([url]);
    }

    //guarda en el local storage la sesión de la terminal seleccionada
    seleccionarTerminal(item){
        console.log("terminal seleccionada" + JSON.stringify(item));
        this.equiposeleccionado = new EquipoSeleccionado();
        this.equiposeleccionado.ID=0;
        this.equiposeleccionado.ID_EQUIPO = item.ID;
        this.equiposeleccionado.ID_USUARIO = 0;
        this.equiposeleccionado.FEC_REG = new Date();
        this.equiposeleccionado.ESTATUS = true;
        this.equiposeleccionado.FECHA_MOD = new Date();
        this.equiposeleccionado.HHMMSS_MOD = new Date();
        this.equiposeleccionado.CVE_USU_MOD = "";

        this._equiposeleccionadoservice.agregar(this.equiposeleccionado,this.token).subscribe(
            response => {
                console.log("Terminal agregada correctamente");
                this.redireccionar('recepcion');
            },
            err=>{
                var errorMessage = <any>err;
                console.log('respuesta del servidor (error): ' + JSON.stringify(errorMessage.error.message));
                this.errorTexto = errorMessage.error.message;
                if(errorMessage !=null){
                    this.status = 'error';
                }
            }
        );
    }
}

import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { Cliente } from '../models/cliente'; //
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class ClienteService{
    public url:string; //url de nuestro backend

    public folioLocalStorage;
    public EquipoTerminalToken;

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    //obiente el ultimo cliente por terminal
    clientePorFolio(token:string): Observable<any>{
        console.log("entre al metodo clienteporfolio ");
        //hay que checar la parte de los tokens
        //var folioJSON = { ID_EQUIPO: ID_EQUIPO, ID_AREA:ID_AREA }
        //let params = JSON.stringify(folioJSON);
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);
        //console.log("valor de los parametros: " + params);
        return this._http.post(this.url+'folio-obtener-cliente', null, {headers:headers});
    }

    obtenerClientePorFolio(folio:string,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'obtener-cliente-por-folio/'+folio,null,{headers:headers});
    }

    obtenerClientePorToken(token):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'obtener-cliente-por-token',null,{headers:headers});
    }

    getFolioLocalStorage(){
        let folio = JSON.stringify(localStorage.getItem('folio'));

        if(folio!="undefined"){
            this.folioLocalStorage = folio;
        }
        else{
            this.folioLocalStorage = null;
        }

        return folio;
    }

    getEquipoToken(){
        let EquipoTerminalToken = JSON.parse(localStorage.getItem('EquipoTerminal'));

        if(EquipoTerminalToken!="undefined"){
            this.EquipoTerminalToken = EquipoTerminalToken;
        }
        else{
            this.EquipoTerminalToken = null;
        }

        return EquipoTerminalToken;
    }

}
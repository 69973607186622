import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'site-header',
    templateUrl:'site-header.component.html'
})

export class SiteHeaderComponent implements OnInit{
    constructor(){

    }
    ngOnInit(){
        
    }
}
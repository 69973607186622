import { Injectable } from '@angular/core'; //permite definir los servicios e inyectarlos en cauqluier otra clase
import { HttpClient, HttpHeaders } from '@angular/common/http'; //para enviar cabezeras en las peticiones ajax
import { Observable } from 'rxjs/Observable'; //recoger las respuestas que nos devuelve el API
import { GLOBAL } from './global'; // la url del API

@Injectable()
export class FolioDetalleService{
    public url:string; //url de nuestro backend

    constructor(public _http:HttpClient){
        this.url = GLOBAL.url;
    }

    obtenerEstudiosPorIdCliente(ID_FOLIO_CLIENTE:number,token:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);
        
        var params = JSON.stringify({ ID_FOLIO_CLIENTE: ID_FOLIO_CLIENTE });

        return this._http.post(this.url+'obtener-estudios-id-cliente',params,{headers:headers});
    }
}
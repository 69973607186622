import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Usuario } from '../../models/usuario';
import { Rol } from '../../models/rol';
import { Empresa } from '../../models/empresa';
import { Sucursal } from '../../models/sucursal';
import { Area } from '../../models/area';
import { UsuarioService } from '../../services/usuario.service';
import { EncuestaAreaService } from '../../services/encuestaarea.service';

@Component({
    selector:'not-found',
    templateUrl:'not-found.component.html'
})

export class NotFoundComponent implements OnInit{
ngOnInit(){

}
}